import React from "react";

import get from "lodash/get";
import ResponderService from "../../services/ResponderService";
import PageLoader from "../../components/PageLoader";
import "./style.css";
import EmohaLogo from "../../assets/images/emoha-new-logo.png";

export default class ResponderKYC extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responder: null,
      isSubmitDisabled: false,
      pageLoader: true,
      completionText: null,
      name: [{
        full_name: null
      }],
    };

    this.responderService = new ResponderService();
  }

  componentDidMount() {
    this.getResponderDetails();
    this.getName();
  }

  getResponderDetails() {
    this.responderService
      .getResponderDetails(this.props.match.params.id)
      .then((response) => {
        if (response && response.data) {
          this.setState({ responder: response.data, pageLoader: false });
        } else {
          this.setState({ pageLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ pageLoader: false }, () => {
          alert("Could not fetch volunteer details. Please try again later.");
        });
      });
  }

  getName() {
    this.responderService
      .getName(this.props.match.params.id)
      .then((resp) => {
        if (resp && resp.data) {
          this.setState({ name: resp.data, pageLoader: false, isSubmitDisabled: resp.data[0].owner.accept_term_condition_time === null });
        } else {
          this.setState({ pageLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ pageLoader: false }, () => {
          alert("Could not fetch Name . Please try again later.");
        });
      });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.setState({
      pageLoader: true,
    });
    var today = new Date(),
      dateNtime =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate() +
        " " +
        today.getHours() +
        ":" +
        today.getMinutes() +
        ":" +
        today.getSeconds();
    const accept_term_condition_time = {
      accept_term_condition_time: dateNtime,
    };
    this.responderService
      .acceptTnc(this.props.match.params.id, accept_term_condition_time)
      .then((resp) => {
        if (resp && resp.data) {
          alert("Form submitted");
          window.location.href = "https://emoha.com/";
        } else {
          this.setState({ pageLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ pageLoader: false }, () => {
          alert("Could not Submit Your Form Data. Please try again later.");
        });
      });
  };

  updateResponderStatus = (verificationStatus) => {
    this.setState({
      isSubmitDisabled: true,
      pageLoader: true,
      completionText: null,
    });
    this.responderService
      .updateResponderStatus(this.props.match.params.id, {
        verificationStatus,
      })
      .then((response) => {
        this.setState({ isSubmitDisabled: false, pageLoader: false }, () => {
          if (verificationStatus === "1") {
            this.setState({ completionText: "Volunteer profile verified." });
          } else {
            this.setState({ completionText: "Volunteer profile rejected." });
          }
        });

        this.getResponderDetails();
      })
      .catch((error) => {
        this.setState({ isSubmitDisabled: false, pageLoader: false }, () => {
          alert(
            "Unable to update volunteer status right now. Please try again later."
          );
        });
      });
  };

  getAnotherResponder = (pos) => {
    this.setState({ pageLoader: true });

    this.responderService
      .getAnotherResponder({
        responder_id: this.props.match.params.id,
        pos,
      })
      .then((res) => {
        this.setState({ pageLoader: false }, () => {
          if (res.data) {
            window.location.href = `/kyc/${res.data}`;
          } else {
            alert("Could not fetch volunteer details. Please try again later.");
          }
        });
      })
      .catch((err) => {
        this.setState({ pageLoader: false }, () => {
          alert("Could not fetch volunteer details. Please try again later.");
        });
      });
  };

  render() {
    const {
      responder,
      isSubmitDisabled,
      pageLoader,
      completionText,
    } = this.state;
    const identityProofs = get(responder, "identityProofs", []);
    const verificationStatus = get(
      responder,
      "userableResponder.veri_status",
      null
    );
    
  
    console.log(this.state);
    return (
      <div className="container">
        <div style={{ border: '3px solid #780001', padding: 10 }}>
        <div className="header-section-white">
          <img src={EmohaLogo} className="header-section-logo-new" alt="Emoha" />
        </div>
        <div className="container">
          <div className="header-subsection">
            <h3 className="header-subsection-tagline">
              Emoha Terms and Conditions of Membership (T&C)
            </h3>
            <h6 className="header-subsection-tagline header-subsection-tagline-new p-3">{this.state.name[0].full_name}</h6>
            <p>
              <b>
                The member (“Member”) who has subscribed to any of the paid
                Services (as defined hereafter) offered by Ignox Labs Private
                Limited (“herein referred to as Emoha/We/Us/Our”) agrees and
                undertakes, that his/her membership is subject to compliance of
                the following T&C. The term “Services” mentioned hereunder shall
                unless it is expressly stated otherwise, include services
                forming part of all 3 (three) membership plans offered by Us,
                i.e. Empower Plan (as detailed in Annexure I), Assure Plan (as
                detailed in Annexure II) and Smart Home Care Plan (as detailed
                in Annexure IV).
                <div>
                  <p>
                    Users of the Emoha App (“App”) who have not subscribed to
                    any of the Emoha Services (“Users”), as well as the Members
                    shall also be subject to the EULA, Disclaimer and Privacy
                    Policy as listed on the Emoha App.
                  </p>
                </div>
              </b>
            </p>
          </div>
        </div>

        <div className="form-section_modified">
          <div className="row">
            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 1. TERM</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  Unless terminated or ceased earlier in accordance with the
                  terms of these T&C, the tenure for the membership or term of
                  use of Services (“ <b>Term</b>”) forming part of: (1) Empower/Assure
                  Plan will be deemed to be effective from the Service
                  Activation Date (as defined hereinafter) and will continue for
                  the period/duration selected by the Member at the time of
                  payment online or if subsequently renewed then such renewed
                  term. ; or (2) Smart Home Care Plan, to be availed by the
                  Member shall be effective from the date when a Care Angel/
                  Care Partner (as defined hereafter) has been deployed in the
                  Member’s house, and will continue for the period/duration
                  selected by the Member at the time of payment online or at the
                  time of filling the Membership Form, or if subsequently
                  renewed then such renewed term.
                </p>
                <p>
                  However, in the event the Member opts to renew the Services
                  beyond the Term initially agreed, We will review the Services
                  required by the Member basis which the Membership Fee (as
                  defined hereinafter) payable in case of renewal of membership
                  will be decided and communicated to the Member or Next of Kin
                  (as defined hereinafter). Pursuant to confirmation by the
                  Member or Next of Kin and on execution of required paperwork
                  and payment, the tenure shall stand renewed for the term and
                  on the conditions as agreed.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u>2. SERVICES</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  <ol class="m">
                    <li>
                      The rendering of Services by Emoha are subject to the
                      terms of these T&C and the submission of registration
                      details by the Member either by downloading the App and
                      filling in the profile information/registering on the App
                      or by filling out the Emoha Membership Form. The Services
                      forming part of Empower and Assured Plan, including the
                      Services of Empower Plan forming part of the Smart Home
                      Care Plan may be availed by maximum of 2 (two) elders from
                      the same household. The Member(s), whose details have been
                      disclosed at the time of online registration on the App or
                      on the Membership Form, will be provided the Services
                      identified and mentioned at the time of registration.
                    </li>
                    <li>
                      The Empower Plan/Assure Plan availed by the Member shall
                      be initiated within 48 (Forty Eight) hours from the
                      payment date i.e “<b>Service Initiation Date</b> ” during
                      which an Emoha team member shall call the Member to
                      confirm relevant details of the elder. The Services will
                      be activated on the date that details relevant to the
                      provision of services is confirmed by Us i.e. the “
                      <b>Service Activation Date</b> ”, and the Member will
                      receive a written confirmation regarding the activation of
                      Services.
                    </li>
                    <li>
                      We reserve the right to suspend the Services in case, we
                      believe that the Member, his/her <b>Next of Kin</b> and/or
                      any of his/her family members are in breach of these T&C.
                    </li>
                    <li>
                      Each Member unconditionally accept and acknowledge the
                      terms of these T&C, and it is further agreed that:
                      <ol class="n">
                        <li>
                          if the Member is not of sound mind or is medically
                          unfit to understand the T&C then all obligations or
                          responsibilities of such Member shall be deemed to be
                          the responsibilities of the Next of Kin. The next of
                          kin (“Next of Kin”) is a relative of the Member whose
                          name and details are mentioned in the Membership Form
                          or at the time of online registration (as the case may
                          be), and such Next of Kin shall be of sound mind,
                          above 18 (Eighteen) years of age and is authorized to
                          make decisions on behalf of the Member
                        </li>
                        <li>
                          We assume that the Next of Kin is legally empowered to
                          engage in shared decision making on behalf of the
                          Member on healthcare matters.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The Member acknowledges that the Services will be
                      available within the ‘<b>Home Zone</b> ’ of the Member.
                      The Home Zone is defined as the region within 5 (Five) km
                      from the address where the elder resides at the time of
                      Service Activation
                    </li>
                    <li>
                      The Member confirms that the Services shall be provided at
                      the residence of the Member (as per details provided in
                      the Membership Form or at the time of online registration)
                      and within the Home Zone
                    </li>
                    <li>
                      In case the Member travels outside of the Home Zone and
                      would like to avail Services in the destination zone (“
                      <b>Destination Zone</b>”), the Member must inform us at
                      least 48 (Forty Eight) hours in advance. We will inform
                      the Member if we are able to provide Services in the said
                      Destination Zone. We are not liable for providing services
                      in the Destination Zone unless We have confirmed the same.
                    </li>
                    <li>
                      The Member understands that grant of access to the
                      Member’s house is essential for -
                      <ol class="n">
                        <li>24x7 emergency support services</li>
                        <li>
                          rendering Assure Services as detailed in Annexure-II
                          including a monthly visit from a general physician and
                          physiotherapist, a bi-monthly call and/or visit by a
                          dietician, annual health check-ups, routine monitoring
                          of the vital signs
                        </li>
                        <li>rendering Smart Home Care Services</li>
                      </ol>
                    </li>
                    <li>
                      With respect to the Services forming part of
                      <b> Clause 2 (h)</b> ,the Member acknowledge that Our team
                      member(s) or personnel shall be granted an unobstructed
                      access to the Member’s house as and when called for by the
                      Member or required in accordance with the Services. In the
                      event access of the house is found to be locked or
                      inaccessible by Our team member, We/Our team member
                      reserve the right to break-in to house and deliver our
                      Services and such forcible access shall not be construed
                      as a trespass nor shall We be liable for any proceedings
                      or actions on this account including that We shall not be
                      held responsible for any delay/inability in rendering the
                      Services should the house be found to be locked or
                      inaccessible. Any damage caused due to forceful entry
                      shall be at the Member’s cost, including any consequential
                      collateral loss. Further, the Member is advised to install
                      a locking system recommended by Us at the main entrance
                      for emergency access by Our team member into the house. We
                      acknowledge that the Services will be performed using
                      reasonable skill, care and standard industry practices
                      and/or applicable laws. In the event of emergencies,
                      We/Our team/Care Angels/Care Partners thereof may
                      endeavour to perform life-saving measures on the Member to
                      the best of their abilities. However, in the event such
                      life-saving measures may result in untoward consequences
                      or injury or loss of life, then Us/Our personnel shall not
                      be responsible or liable for any untoward consequences
                      including loss and/or damages.
                    </li>
                    <li>
                      <b>For the purpose of Smart Home Care Services:</b>
                      <ol class="n">
                        <li>
                          The Care Partner/Care Angel Services are third party
                          services, provided by third party service providers
                          i.e. General Duty Assistant(GDA)- Care Partner/ Nurse
                          - Care Angels, who are not Our employees, and We only
                          act as a facilitator with respect to the Services
                          rendered by them.
                        </li>
                        <li>
                          The Services will be provided to the Member on the
                          days and for the hours or shift as may be intimated
                          and agreed in advance between the Member and Us/Our
                          authorised personnel. Any Services for period beyond
                          the agreed shift/hours would entail overtime charges
                          for the Care Partner/Care Angel providing care and
                          Services and the Member shall pay prescribed amounts
                          to Us, further this is subject to the availability of
                          a Care Partner/Care Angel. The overtime charges
                          intimated to the Member in advance, will be charged by
                          Us and shall be paid by the Member upon being
                          requested.
                        </li>
                        <li>
                          In the event the assigned Care Partner/Care Angel
                          fails to arrive at the house for rendering Services
                          and care, then upon receiving intimation of the same
                          from the Member, We will make all efforts to send the
                          replacement at Member’s house at the earliest.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u>
                    3. APP/CALL OR SENSOR BASED EMERGENCY COORDINATION SERVICE
                  </u>
                </h6>
              </div>
              <div className="col-sm-9">
                <ol class="m">
                  <li>
                    The emergency coordination services to be rendered by Us are
                    based on the Service availed by the Member. The emergency
                    coordination service under the Empower and Assure Care Plan
                    are app based/call-based service (accompanied with panic
                    buttons) predominantly. The Member may opt for either or
                    both of the Services.
                    <p>
                      <b>For App based emergency coordination service -</b>
                    </p>
                    <ol class="n">
                      <li>
                        The Member shall download the App in his/her mobile and
                        use the App or call Us in case of emergencies.
                      </li>
                      <li>
                        We reserve the right to modify and update the app from
                        time to time and the Member should regularly check for
                        and update the App on his/her device to enjoy all
                        features of the App
                      </li>
                    </ol>
                    <p>
                      <b>For call based emergency coordination service –</b>
                    </p>
                    <p>
                      The Member may even avail the emergency coordination
                      service forming part of the Service plan availed by such
                      Member, by calling at the 24/7 Toll Free Emergency
                      Co-ordination Number listed on Our website.
                    </p>
                    <p>
                      <b>For sensor-based emergency coordination service -</b>
                    </p>
                    <ol class="n">
                      <li>
                        A sensor is installed at the house of the Member on
                        additional charges payable by the Member The cost of the
                        sensor is exclusive of SIM card and batteries, which are
                        necessary for the functioning of the Emoha sensor. For
                        avoidance of doubt, Emoha shall not be responsible for
                        arranging the SIM card and/or batteries. All terms,
                        conditions and warranties relating to the use of the
                        sensor for emergency coordination services shall be as
                        specified by Us at the time of purchase and/or
                        installation of the sensor.
                      </li>
                      <li>
                        The sensors shall be installed, un-installed and
                        maintained by Our authorized personnel only. Where any
                        tampering (including changing the location of
                        installation), re-installation, maintenance and
                        un-installation of the sensor is carried out by the
                        Member or his/her family members and/or any third party
                        without Our knowledge and approval then We shall not be
                        liable for any malfunction or non-functioning of the
                        sensor and consequent loss or damages caused to the
                        Member. Any damage and/or malfunction of the sensors
                        beyond Our control and/or due to third party acts may
                        also render us unable to render Services.
                      </li>
                      <li>
                        The sensors rely on third party power resource like a
                        power generator or invertor. We therefore, recommend the
                        Member to have power backup for sensors. In the event of
                        a power failure of longer than 1 (one) hour, sensors
                        will stop working and Member will no longer be able to
                        avail the Services. We/Our team members will make its
                        best efforts to make routine visits to check if the
                        sensors are in order. However, Members are expected to
                        be vigilant and if the sensors are not working, intimate
                        Us.
                      </li>
                      <li>
                        In the event the Member chooses to use any third-party
                        sensor or utilize their existing installed sensors, We
                        shall neither be responsible nor liable towards any
                        defects, after sale services, technical glitches and/or
                        non-functioning of such third-party sensors. Further, if
                        as a result of any of the aforementioned We are unable
                        to effectively provide Services to the Members, We shall
                        not be held liable in any manner whatsoever and, in this
                        respect the Member consequently disclaims all
                        liabilities qua Us.
                      </li>
                    </ol>
                  </li>
                </ol>
                <ol class="m">
                  <li>
                    Our Emergency Co-ordination Services, whether requested
                    through App, call or sensors is subject to successful
                    operations of intermediate telecom operators for relaying
                    signals between the Member’s home and Us. We will not be
                    responsible for any inability to render Services in the
                    event the same is accountable to network issues if any.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 4. MEMBERSHIP FEE</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <ol class="m">
                  <li>
                    The Member shall pay, in advance, the Membership Fee for the
                    Services forming part of the Empower or Assure Plan, as more
                    particularly detailed and specified at the time of purchase
                    of one or more of the Emoha Membership Plans.
                  </li>
                  <li>
                    In case of Smart Home Care Plan, the Membership Fees shall
                    be duly and timely paid in accordance with the timelines
                    agreed at the time of availing the Services forming part
                    thereof, and rendering of Services under the Smart Home Care
                    Plan are subject to timely payment of any/all instalments.
                    In the event the Member fails to make any payment due and
                    payable to Us, within a period of 15 (Fifteen) working days
                    from the receipt of an invoice issued by Us, We shall not be
                    liable or obligated to perform any of the Services.
                    Moreover, such non-performance by Us, due to non-payment of
                    any dues within the stipulated period of time mentioned
                    herein, shall not amount to breach on Our part. The Member
                    agrees and undertakes that in case of any non-payment beyond
                    15 (Fifteen) working days from date of receipt of invoice
                    issued by Us, the Member will get a cure period of further
                    15 (Fifteen) days beyond which, in case the payment is still
                    not made, such non-payment shall amount to a breach by the
                    Member and shall entitle Us, to terminate the Smart Home
                    Care Plan and seek damages apart from the outstanding
                    payment along with interest @ 12% (Twelve percent) per annum
                    from the date of expiry of such notice period until full and
                    final realization of the dues. For the sake of clarity, in
                    the event the Member clears the outstanding amount along
                    with interest, We shall continue rendering the Services.
                  </li>
                  <li>
                    We accept payment of all amounts specified herein solely by
                    the methods as stated on the App or as communicated by Us to
                    the Member during the sign-up process or from time to time
                    during the Term. In case the payment is not paid in advance,
                    the Member is requested to inform Us promptly of any changes
                    to Member’s payment information. Any fee, charges or
                    consequences towards declined credit cards or dishonour of
                    cheques shall be to the account of the Member.
                  </li>
                  <li>
                    Member confirms and acknowledges that the Membership Fees
                    and all other amounts, expenses and charges payable to Us
                    are fair and reasonable and are the essence of this
                    arrangement. It is further acknowledged that where any
                    costs, charges or expenses are incurred by Us/Our team
                    member/Care Angel/Care Partner in rendering the Services,
                    then same shall be paid/reimbursed to Us by the Member
                    within 7 (Seven) days from raising the invoice.
                  </li>
                  <li>
                    If, additional or multiple services (of different types) are
                    required or requested for, the rates/fee as then prevalent
                    shall apply, along with such terms and conditions as may be
                    applicable in respect thereof.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 5. TAXES</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  The Member shall be liable to bear and promptly pay all local
                  and Government taxes as may be levied at present and/or which
                  may be levied at any future date in respect of the Membership
                  Fee, other charges and/or Services. If applicable, such taxes
                  and charges shall be paid by the Member as per Our
                  directions/instructions. It is further clarified that all
                  taxes payable by the Member pursuant to this T&C are in
                  addition to the Membership Fee and/or any other charges
                  payable by the Member.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 6. MEMBER’S RESPONSIBILITY</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <ol class="m">
                  <li>
                    Member is using the Services during the Term of membership,
                    at his/her own free will, decision and risk.
                  </li>
                  <li>
                    The Member confirms and undertakes that all information
                    provided by him/her or on his/her behalf by the
                    representative/family member is accurate and complete in all
                    material respects.
                  </li>
                  <li>
                    While providing the Services We will rely on information
                    including medical history, records and reports made
                    available to Us and, unless We expressly agree otherwise, We
                    will have no responsibility to evaluate or verify it.
                  </li>
                  <li>
                    The Member shall from time to time update Us qua the
                    Member’s medical condition and shall provide updated medical
                    information, records and reports.
                  </li>
                  <li>
                    We shall not be responsible for authentication of medical
                    records, or any wrong interpretation of medical records by
                    clinical personnel at any point of time. The Member is
                    expected to carry original documents for any clinical
                    consultation/procedure/intervention. In case of any
                    discrepancy in Our digitized records and the originals
                    shared by the Member at clinical
                    consultation/procedure/intervention, original medical
                    records shall prevail and be considered and the Member shall
                    not raise any claims against Us/Our team members/Care
                    Partners/Care Angels in this regard.
                  </li>
                  <li>
                    We will be entitled to maintain digital records of Member’s
                    clinical history. However, We shall not be responsible for
                    the clinical interpretation and, in turn, recommendation of
                    medical intervention. We will solely act as a data
                    repository for medical records.
                  </li>
                  <li>
                    The Member shall use all the Services available with care
                    and caution. The Member agrees to abide by and follow all
                    instructions placed or provided by Us from time to time in
                    respect of use/availing the Services.
                  </li>
                  <li>
                    The Member shall be responsible for safety and security of
                    Our team member(s)/Care Angels/Care Partners rendering
                    Services at the house of the Member
                  </li>
                  <li>
                    Our team member/Care Partner/Care Angel will not drive the
                    Member’s vehicle. If Our team member/Care Partner/Care Angel
                    is required to drive Member’s vehicle in case of emergencies
                    at the Member’s insistence, then same shall be done at the
                    Member’s sole risk and responsibility. Further, the Member
                    understands, confirms and undertakes that We/Our team
                    members/Care Partner/Care Angel shall not be responsible or
                    held liable for safety of Member, vehicle driven by Our team
                    members/Care Partner/Care Angel, and Member’s house in such
                    a scenario and the Member shall keep Us and/or Our team
                    members/Care Partner/Care Angel indemnified in this regard.
                  </li>
                  <li>
                    Any refusal by the Our team member/Care Partner/Care Angel
                    from performing any acts or duties which are not covered
                    under the Services as detailed herein shall neither be
                    deemed as refusal or deficiency by Us/team member/Care
                    Partner/Care Angel in rendering Services nor be considered
                    as breach of this T&C by Us/team member/Care Partner/Care
                    Angel.
                  </li>
                  <li>
                    In certain circumstances (such as severe weather, lockdown,
                    pandemic, riots, curfew etc.), We may determine it is not
                    safe for Our team member/Care Partner/Care Angel to travel
                    and provide Service at Member’s House and that day We may
                    have to cancel that day’s Services or provide Services
                    (where possible) over electronic means. Our Services are
                    subject to appropriate weather, city civic conditions, Force
                    Majeure conditions including but not limited to riots,
                    lockdown, pandemic, curfew, war, terrorist acts or any
                    external circumstances not under Our control
                  </li>
                  <li>
                    The Member takes full responsibility of the safekeeping and
                    safety of Members personal property, goods and belongings
                    including but not limited to money, jewellery, eyeglasses,
                    dentures or hearing aids etc. We encourage our members to be
                    extremely vigilant with their personal belongings.
                  </li>
                  <li>
                    Apart from the responsibilities stated herein above, the
                    Member availing the Smart Home Care Services, agrees and
                    acknowledges to also fulfill the following responsibilities
                    qua such Services
                    <ol class="n">
                      <li>
                        Where the Care Partners/Care Angels is staying at the
                        house of the Member, the Member shall ensure that
                        sufficient food, clean drinking water, a clean and safe
                        place to sleep, access to a functional washroom with
                        toilet and shower/bath facilities and other basic
                        necessities of this nature shall be provided to them.
                      </li>
                      <li>
                        Further, the Member must register the Care Partners/Care
                        Angels with the local police station as an additional
                        precautionary measure.
                      </li>
                      <li>
                        The Member will be responsible for supplying all
                        supplies (i.e. cleaning, personal care etc. including
                        latex gloves, sanitizer etc. needed for the safe
                        execution of any kind of personal care) and equipment
                        which may be necessary in the provisioning of the
                        Services. Extra charges will apply if We have to arrange
                        for and/or provide these supplies. Further, if requested
                        by the Member, We can provide/arrange medical equipment
                        to the Member’s house at additional cost and on the
                        terms and conditions prescribed by Us.
                      </li>
                      <li>
                        Care Partners are not authorized to administer any kind
                        of medications thus, any refusal on their part to do the
                        same shall not amount to breach of the T&C nor amount to
                        deficiency in Service. However, if the Care Partner is
                        pressurized by the Member or any other person to
                        administer any medicine then neither the Care Partner
                        nor We shall be responsible or liable in any manner what
                        so ever.
                      </li>
                      <li>
                        The Member shall inform Us/Our team/Care Partners/Care
                        Angels, if the Member or any person who might be staying
                        at the house of the Member is suffering from any
                        communicable disease or may have come in contact with
                        someone in the past 14 (Fourteen) days, who is suffering
                        from any communicable disease like TB, Hepatitis,
                        COVID-19 etc. which is likely to have an adverse effect
                        on the health of Care Partners and Care Angels. The
                        Member also agree and acknowledge to indemnify and hold
                        Us/ Our team/Care Partners/Care Angels harmless against
                        any loss, liability or damage caused due to wilful
                        neglect or negligence in intimating Us/Our team
                        members/Care Partners/Care Angels about the
                        aforementioned. Further, on being informed or learning
                        about the aforementioned situation, We reserve the right
                        to not render any further Service to the Member and such
                        refusal to render Services shall not amount to breach of
                        the these T&C
                      </li>
                      <li>
                        The Member or his/her family members or any person who
                        might be staying in the house of the Member shall not
                        misbehave, threaten, physically man-handle or detain the
                        Care Partners/Care Angels against his/her wishes as the
                        same shall be construed as illegal detention and the
                        Member will be responsible for any contingencies/harm
                        caused to the Care Partner/Care Angel directly or
                        indirectly due to the acts or omission on part of the
                        Member.
                      </li>
                    </ol>
                  </li>
                  <li>
                    No Member shall:
                    <ol class="n">
                      <li>
                        either by words or by action cause or permit anything
                        that is in all reasonableness likely to be abusive,
                        offensive, derogatory, disruptive or dangerous for Our
                        team members/Care Partner/Care Angel providing the
                        Services;
                      </li>
                      <li>
                        use the Services, to conduct or pursue any illegal or
                        offensive or obscene or abusive activities.
                      </li>
                      <li>
                        poach, solicit or entice away or attempt to poach,
                        solicit or entice away any officer or
                        employee/trainee/Care Partner/Care Angel involved
                        with/engaged by Us, or Our affiliates;
                      </li>
                      <li>
                        request or require Our team members/Care Angels/Care
                        Partners rendering Services to do or perform activities
                        which are beyond its scope of work;
                      </li>
                      <li>
                        no Member shall give any gifts, monies or kickbacks to
                        Our team members/Care Angels/Care Partners.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 7. REPRESENTATION AND COVENANTS OF MEMBER</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  <b>
                    The Member/Next of Kin hereby represents and warrants that:
                  </b>
                </p>
                <ol class="m">
                  <li>
                    Member/Next of Kin has all requisite legal power and
                    authority to enter into and abide by the terms and
                    conditions as enumerated under these T&C;
                  </li>
                  <li>
                    All the information, records, reports including medical
                    records provided by Member or on his/her behalf are true and
                    accurate and We may rely on the same for the purposes of
                    rendering the Services. Further, in case any liability
                    arises out of use of such information, records, reports then
                    We shall not be liable for any reason whatsoever towards the
                    Member and/or any third party;
                  </li>
                  <li>
                    The Member, acknowledge and accept that, the Member’s
                    personal information disclosed to Us, shall be used for the
                    purpose of providing Services on the App. and, shall be
                    collected, used, stored and shared in accordance with the
                    terms of the privacy policy of the App. In the event the
                    Member or Member’s representative wish to withdraw the
                    consent at any time here after, the Member or Member’s
                    Representative may ask for the data/information to be
                    removed from the App., by submitting a request in this
                    regard with Us at <b>eldersfirst@emoha.com</b>. On receiving
                    such request, We shall remove such information at the
                    earliest possible. However, the Member or Member’s
                    representative accept and acknowledge that in the event such
                    consent is refused, or withdrawn, We retain the right to
                    either not provide or withdraw the Services for which the
                    said information was sought. It is further accepted and
                    acknowledged that the inability to provide the Services in
                    such event shall not amount to default or breach on Our part
                    and We shall not be liable for any damages, claims or loss
                    caused consequently;
                  </li>
                  <li>
                    The rights, benefits, privileges and obligations of the
                    Member are personal and non-transferrable in nature and the
                    Member hereby represents and warrants that the Member shall
                    not transfer or let any third-party use and/or access the
                    Services;
                  </li>
                  <li>
                    In case of emergency qua the Member, We reserve the right to
                    take such actions as We may deem prudent and necessary and
                    Member or his/her family members shall provide full
                    co-operation in respect of the same; and
                  </li>
                  <li>
                    While rendering the Services to the Member, We generate and
                    store data for the Member, their medical records,
                    activities/engagements with Us etc. In this regard, the
                    Member hereby expressly, unequivocally and unconditionally
                    consents and agrees that such data and information can be
                    used/shared by Us in case of emergencies and/or for
                    marketing purposes, research or survey purposes (if
                    consented under the Membership Form/ at the time of
                    registration on the App). However, such data and record will
                    be shared by Us for marketing purposes, research or survey
                    purposes (if consented), in anonymized manner and no
                    royalties, fees or charges will be payable to the Member for
                    the same.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u>
                    8. ACKNOWLEDGEMENT AND COVENANTS OF MEMBER QUA THIRD PARTY
                    SERVICES
                  </u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  <b>
                    Notwithstanding, anything contained in these T&Cs, the
                    Member agrees and acknowledge that:
                  </b>
                </p>
                <ol class="m">
                  <li>
                    Certain Services stated herein will be provided through/by a
                    third-party service provider/vendor/facilitator/channel
                    partner, in which case the Member may be required to sign
                    separate agreement(s)/letter(s)/document(s) as may be
                    required or deemed necessary by such third party.
                  </li>
                  <li>
                    We are only a coordinator qua third party services and thus,
                    We shall not be liable in manner what so ever, directly or
                    indirectly, for any act or omission on the part of such
                    third party which results into any kind of damage, loss,
                    claim, injury (including death) etc
                  </li>
                  <li>
                    We shall not be liable for any misrepresentation, inaccuracy
                    or deficiency in Service, refusal to provide any discounts,
                    privileges or services represented or warranted by any third
                    party. Unless expressly provided, the Membership Fees shall
                    not include fees/cost of any third-party services.
                  </li>
                  <li>
                    The Member authorizes Us to share relevant data with
                    third-party service provider to ensure that they are able to
                    provide the Member the necessary Services and for research
                    purposes (if consented).
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 9. TERMINATION AND EXPIRY</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  <b> TERMINATION BY US</b>
                </p>
                <ol class="m">
                  <li>
                    We may terminate the membership at any time, with or without
                    cause, by giving 7 (Seven) days advance written notice to
                    the Member. We may withhold Services and/or terminate the
                    membership by giving 7 (Seven) days prior written notice to
                    the Member upon breach/default by the Member of these T&C
                    and/or any rules, regulations and instructions intimated to
                    the Member from time to time and failure of the Member to
                    rectify the same within the given notice period.
                  </li>
                  <li>
                    Notwithstanding anything contained in (a) and (b) above, We
                    shall have the right to terminate the membership immediately
                    upon notice to the Member:
                    <ol class="n">
                      <li>
                        at any time if the Member is found to be engaging in
                        behaviour which is a threat to the mental and/or
                        physical health or safety of Our team members/Care
                        Partners/Care Angels providing care and Services at the
                        house of the Member
                      </li>
                      <li>
                        at any point, before or after the Member has accessed
                        the Services, upon finding about any misrepresentation
                        or omission made by the Member while providing
                        information specifically with regard to Medical history
                        or resources of the Member
                      </li>
                    </ol>
                  </li>
                  <li>
                    We reserve the right to not render Services, upon finding
                    that We/Our team members /Care Partners/Care Angels will not
                    be able to take the Member’s care owing to Member’s medical
                    condition including if the Member or any person residing at
                    his house are suffering from a communicable disease
                    including COVID-19 (which was not disclosed to Us earlier or
                    which condition happened/occurred at a later stage). Our
                    decision in this regard shall be final and Our liability
                    will be limited to refunding the Membership Fee net of taxes
                    (to the extent not exhausted), subject to necessary
                    deductions, if any, to the Member.
                  </li>
                </ol>
                <p>
                  <b>TERMINATION BY A MEMBER</b>
                </p>
                <ol class="m">
                  <li>
                    A Member may terminate the membership of Assure/Empower Plan
                    by giving Us a 1 (One) month’s prior written notice. During
                    the notice period, the Member shall be liable to pay the
                    Membership Fee and other charges, if applicable. In case of
                    Smart Home Care Services - A Member may terminate these
                    Services by giving Us a 7 (Seven) days prior written notice.
                    During the notice period, the Member shall be liable to pay
                    the Membership Fee and other charges, if applicable, for the
                    time period the Services are being used.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u>
                    10. CESSATION OF MEMBERSHIP IN CASE OF DEATH OR NON-RENEWAL
                  </u>
                </h6>
              </div>
              <div className="col-sm-9">
                <ol class="m">
                  <li>
                    <b>Non-renewal:</b> Our responsibility to render the
                    Services may also cease to exist in the event of non-renewal
                    of the Membership by the Member prior to expiry of the Term.
                    If the Member intends to continue the Membership, it shall
                    file a written request with Us at least 14 (Fourteen) days
                    prior to the expiry of the Term. On receipt of such request
                    we shall renew the Services for such period as may be agreed
                    at the time of renewal.
                  </li>
                  <li>
                    <b>Death: </b> This membership shall terminate automatically
                    upon death of the Member receiving the Services. The
                    Member/Next of Kin shall be charged the Membership Fees and
                    other charges (as applicable) till the period Services are
                    provided. Notwithstanding anything contained herein before,
                    it is clarified that in the event the Services forming part
                    of Empower and Assured Plan, are being rendered to 2 (Two)
                    Members residing under 1 (One) household, on death of 1
                    (One) Member, the Services shall continue for the other
                    Member, until expiry/termination of this Agreement or the
                    death of such Member.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u>
                    11. CONSEQUENCES OF CESSATION OR TERMINATION OR
                    DISCONTINUANCE
                  </u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  We shall not be liable to render any Services post
                  termination, cessation or expiry of the membership. In case of
                  the Assure Plan where there are sensors installed, upon
                  termination, cessation or discontinuance of the Services, We
                  shall be entitled to disconnect Our responder system from the
                  sensor installed at Member’s house or app installed by the
                  Member and/or render other Services as mentioned herein.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 12. REFUNDS</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <ol class="m">
                  <li>
                    In case Member wishes to terminate Empower Plan before
                    expiry of the Term of membership as mentioned at the time of
                    purchase of the Services or if renewed, then refund of any
                    advance amounts will be made to the Member in full and the
                    Member agrees and confirms the same.
                  </li>
                  <li>
                    In case Member wishes to terminate Assure Plan before expiry
                    of the Term of membership as mentioned at the time of
                    purchase of the Services or if renewed then the renewed
                    period, then refund of any advance amounts will be made to
                    the Member after withholding 15 (Fifteen) days subscription
                    amount from the amount paid, at the prevailing rate of 15
                    (Fifteen) days Service subscription and the Member agrees
                    and confirms the same. However, where the amounts lying with
                    Us pertains to period of less than 15 (Fifteen) days then
                    the entire amounts lying with Us shall stand forfeited and
                    We shall not be liable for refund of any amount to the
                    Member.
                  </li>
                  <li>
                    Notwithstanding anything contained hereinbefore, in case of
                    membership of Smart Home Care Services under Smart Home Care
                    Plan
                    <div>
                      If a member wishes to discontinue the Smart Home Care
                      Services before the expiry of the Term of membership as
                      mentioned in the Membership Form or at the time of online
                      registration, then the pro-rata refund of Membership Fee
                      will be made to the Member after deducting the charges qua
                      the Smart Care Services availed by the Member till the
                      date of such termination.
                    </div>
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 13. OUR RELATIONSHIP WITH THE MEMBER</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <ol class="m">
                  <li>
                    We will provide the Services to the Member as an independent
                    service provider and not as the employee, agent, partner or
                    in joint venture
                  </li>
                  <li>
                    The Member shall have no right, power or authority to assume
                    or to bind or to create any obligation on behalf of or in
                    Our name or Our team member’s name, in any manner whatsoever
                  </li>
                  <li>
                    The Member agrees and acknowledges that We reserve the right
                    of admission of the Member and/or cancellation of membership
                    of the Member, at Our sole discretion, for any reason
                    whatsoever
                  </li>
                  <li>
                    The Member shall ensure that the Next of Kin representative
                    or emergency contact person (as specified and shared in the
                    Membership Form or at the time of online registration) shall
                    not transfer or otherwise assign his/her duties and
                    obligations qua the Member and this membership, without
                    prior written intimation to Us.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 14. INDEMNITY</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  The Member hereby agrees and undertakes to indemnify Care
                  Partners/Care Angels, Us, Our team members, our employees, Our
                  agents, Our representative from and against any and all
                  claims, including third party claims, liabilities, and
                  expenses including reasonable attorneys’ fees, resulting from
                  any breach of the T&C and/or any breach of law in any material
                  respect, by the Member or Next of Kin or family members of the
                  Member.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 15. LIMITATION OF LIABILITY</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <ol class="m">
                  <li>
                    The Member understands that We have agreed to render Our
                    Services to the Member under good faith and shall make best
                    efforts to provide the Services which are safe, healthy and
                    suitable to the needs of the Member and therefore, We shall
                    not be liable for any special, indirect, consequential, or
                    incidental damages, or hurt caused to the Member. Further,
                    We shall not be liable for damages or loss of health, loss
                    of material possession, or death of the Member arising out
                    of or related to the act not attributable to Us and/or Our
                    team member/affiliates/partners/service providers/Care
                    Partners/Care Angels.
                  </li>
                  <li>
                    In any event, Our liability will not exceed the amount paid
                    by the Member to Us in last preceding quarter of the Term
                    from the date such liability arose.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 16. NOTICES</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  Any/all notices or consents will be given via registered speed
                  post or short message service (SMS) or email of the Member
                  (details of which have been shared in the Membership Form or
                  at the time of online registration) and will be effective or
                  deemed served on the first business day after being sent.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 17. JURISDICTION AND GOVERNING LAW</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  The Courts at Gurugram, Haryana shall have jurisdiction over
                  the disputes relating to the membership, Membership Form, and
                  these T&C.
                  <div>
                    The arrangement/membership and T&C shall be governed in
                    accordance with laws of India.
                  </div>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 18. POINT OF CONTACT</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  At all times, We shall address Our communications, notices,
                  correspondences etc. to the person(s) having such details as
                  are mentioned by the Member in the Membership Form or at the
                  time of online registration. Any change in details shall be
                  intimated to Us by the Member in writing failing which We
                  shall not be liable for loss or non-receipt of the
                  communication, notices, correspondences resulting in any
                  losses or non-compliances.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 19. MODIFICATION OF THE TERMS AND CONDITIONS</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  We reserve the right to update the terms and conditions for
                  use of Services, Membership Fees and other charges at any time
                  and same will be notified to the Member over the Emoha App and
                  over email. Emoha shall deem the T&C as an unequivocal and
                  unconditional acceptance of such modifications if no
                  objections is raised within 3 (Three) days of dispatch of the
                  updated terms and conditions.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 20. SEVERABILITY</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  If any provision of these T&C (in whole or part) is held to be
                  illegal, invalid or otherwise unenforceable, the other
                  provisions shall remain in full force and effect.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 21. CONSENT FOR MARKETING</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  Member and/or the representative agree, acknowledge and
                  expressly consent that We can use their testimonials, pictures
                  and videos as on social media, brochures, website or any other
                  collateral for marketing purposes.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u>22. FORCE MAJEURE</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  We shall not be liable, in any manner whatsoever, for any
                  breach/default of the Services caused by circumstances beyond
                  Our control including without limiting to any acts of God,
                  pandemic, strike, orders of authorities etc. Further, in case
                  of a force majeure event, if We are unable to render any part
                  of the Services, the same shall not be considered breach or
                  default on Our part and the future coSurse of action shall be
                  mutually decided between the Parties.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 23. WELCOME KIT, MEMBERSHIP FORM AND T&C</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  It is hereby clarified that the Welcome Kit (if applicable),
                  Membership Form (if signed), online Registration Forms and
                  these T&C (including all annexures to these T&C) comprises of
                  information, terms and conditions for accessing and using the
                  Services and the specific commercials, details and
                  understanding relating to the Services, Membership Fees,
                  payments terms, Member details, term, complimentary and
                  additional services etc. The T&C shall form part of the
                  Membership Form (if signed), Registration Form and shall be
                  read in conformity with the same
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <h6>
                  <u> 24. SURVIVA</u>
                </h6>
              </div>
              <div className="col-sm-9">
                <p>
                  Neither the expiration nor termination of the Services shall
                  affect such provisions of these T&C that by their very nature
                  must survive such expiration or termination or which out of
                  necessity must continue to have effect after such expiration
                  or termination.
                </p>
              </div>
            </div>

            <div className="row annexure_row">
              <div class="annexure">
                <div class="multiAnnexure">
                  <div class="annexure-1">
                    <div class="heading">
                      <p>
                        <b>
                          ANNEXURE-I<u></u>
                        </b>
                      </p>
                      <p>
                        <b>
                          <u> SERVICES TO BE PROVIDED UNDER EMPOWER PLAN</u>
                        </b>
                      </p>
                    </div>
                    <div class="annexure_body">
                      <ol>
                        <li>
                          <b>
                            <u>Emergency Coordination Services </u>:
                          </b>
                          The emergency coordination and support services
                          through App/call (more particularly described in
                          <b>Clause 3</b> of the T&C). As part of the Emergency
                          Coordination Services, We would engage and remotely
                          co-ordinate with third party service providers to
                          arrange for ambulance dispatch to the Member’s
                          location. While We would endeavour to have the third
                          party deliver on its commitments, however, we shall
                          not be liable in any manner whatsoever for any
                          omissions, failure or negligence towards undertaking
                          the duties of the third-party provider. If the Member
                          requires additional emergency response service such as
                          a paramedic nurse visit, this service would be charged
                          separately.
                        </li>

                        <li>
                          <b>
                            <u> Engagement Activities </u>:
                          </b>
                          Members will have access to activities and events –
                          both paid and free, in person and/or virtual -
                          organized by Us or any of Our subsidiaries or
                          partners. In case of the paid event, Member will have
                          to pay for the same.
                        </li>
                        <li>
                          Please note that in no event, We will be liable for
                          any acts of the third parties (referred, booked or
                          arranged for) and services, details or information
                          provided to the Member. Any disputes, claims and
                          liabilities between the Member and such third party
                          shall be handled and managed by the respective parties
                          and We shall neither be liable for the same nor be
                          made a party to such disputes. Members accessing
                          information, engagement, education and entertainment
                          programs on the Emoha App or on Online programs /
                          webinars / video conference are expected to exercise
                          discretion in adopting any recommendation given that
                          programs are for general viewing
                        </li>
                        <li>
                          <b>
                            <u> Third party discounts </u>:
                          </b>
                          Members may be entitled to a special services /
                          discounts / privileges by third parties as part of
                          membership benefits of having the Emoha Membership.
                          However, We are not responsible for quality of
                          services or rendering / honouring of the discounts /
                          privileges in case the third party does not honour the
                          discounts.
                        </li>
                        <li>
                          <b>
                            <u>Regular calls </u>:
                          </b>
                          Member is entitled to receive regular phone calls on
                          the phone number provided at the time of online
                          registration5 days a week (excluding weekends and
                          national holidays) by Emoha Elder Relationship
                          Managers. Member can choose to reduce the number of
                          days of calls by informing the Elder Relationship
                          Manager.
                        </li>
                        <li>
                          <b>
                            <u>
                              If member has paid for additional Care Partner or
                              Care Angel services*, member will be entitled to
                              the following third-party services
                            </u>
                          </b>
                          and as mentioned in <b>Clause 8</b> of this T&C, Emoha
                          shall not be liable in any manner whatsoever, towards
                          the same.
                          <ol>
                            <li class="m">
                              Care Partner (GDA) support to provide assistance
                              with Activities of Daily Living and medication
                              reminders.
                            </li>
                            <li>
                              Care Angel (Nurse) support to provide assistance
                              with Activities of Daily Living, and including but
                              not limited to: medication administration, basic
                              Nursing Services such as Ryle’s tube feeding, PEG
                              tube feeding, injections, wound care, pressure
                              sore care, catheter insertion or removal, or
                              advanced Nursing Services such as tracheostomy
                              suctioning, CPAP/BIPAP care, care of an individual
                              on a ventilator etc.
                            </li>
                            <li>
                              Visiting Physical Therapy Services, if required in
                              the care plan.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <p>
                        *Service eligibility is determined after the completion
                        of an assessment of the Member by a qualified Emoha
                        nurse.
                      </p>
                    </div>
                  </div>

                  <div class="annexure-2">
                    <div class="heading">
                      <p>
                        <b>
                          <u> ANNEXURE-II</u>
                        </b>
                      </p>
                      <p>
                        <b>
                          <u>SERVICES TO BE PROVIDED UNDER ASSURE PLAN</u>
                        </b>
                      </p>
                    </div>
                    <div class="annexure_body">
                      <p>
                        In addition to above services, the following will be
                        included for Members subscribing for the Assure Care
                        Plan:
                      </p>
                      <ol>
                        <li>
                          <b>
                            <u>Services of a Community Coordinator :</u>
                          </b>
                          The membership plan entitles the Member to avail 4
                          (Four) hours of Community Coordinator’s time on a
                          monthly basis to help the elder on services such as to
                          accompany an elder to a hospital, or other errands,
                          visiting bank, pickup/drop off of items etc. Emoha
                          reserves the right to accept the nature of the
                          errand/job as requested by the elder and will inform
                          the Member should the service request be of a nature
                          which Emoha is unable to deliver.
                        </li>

                        <li>
                          <b>
                            <u> Convenience Services </u>:
                          </b>
                          24x7 convenience services available for addressing any
                          concerns of the Member or providing any help or
                          services required by the Member such as travel
                          arrangements, seeking appointments, show bookings etc.
                          Our service and co-operation will be limited to making
                          the required arrangement or giving the required
                          referral or information. We reserve the right to
                          refuse to perform any errand / job requested by the
                          Member, should such errand/job be of a nature which We
                          would not be able to deliver and, We shall
                          inform/intimate the Member in this regard.
                        </li>
                        <li>
                          <b>
                            <u>Health Services </u>:
                          </b>
                          Members of the Assure Plan will be entitled to a
                          monthly visit by a general physician, bi-monthly calls
                          from a dietician, fortnightly house visit by a nurse
                          to check vital signs and capture the Electronic Health
                          Record of the member/s, a monthly visit by a
                          physiotherapist as well as regular calls from Our
                          Elder Relationship managers. Members will also be
                          entitled to Full Body Checkup (FBC), as detailed in
                          Annexure III from a reputed third-party health
                          diagnostic partner.
                        </li>
                        <li>
                          <b>
                            <u> Sensors </u>:
                          </b>
                          The Emoha Sensor box will be included for Members who
                          opt for the 12 (Twelve) months Assure Plan
                          subscription.
                        </li>
                        <li>
                          <b>
                            <u>Emergency Response Service </u>:
                          </b>
                          Members with Assure Plan are entitled to receive
                          paramedic emergency response and support to assist
                          with hospital coordination during an emergency
                          situation at the Member’s location. In the event of
                          emergencies, We/Our team thereof may endeavour to
                          perform life-saving measures on the Member to the best
                          of their abilities. However, in the event such
                          life-saving measures may result in untoward
                          consequences or injury or loss of life, then We/Our
                          team shall not be responsible or liable for any such
                          untoward consequences including any loss and/or
                          damages.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div class="annexure-3">
                    <div class="heading">
                      <p>
                        <b>
                          <u> Annexure III</u>
                        </b>
                      </p>
                      <p>
                        <b>
                          <u> Full Body Checkup</u>
                        </b>
                      </p>
                    </div>
                    <div class="annexure_body">
                      <p>
                        The full body check-up consists of the following tests:
                        <div>
                          CBC, Urinalysis, Glucose (F), Triglycerides,
                          Cholesterol Total, HDL, LDL, VLDL, LDL/HDL Ratio,
                          Cholesterol Total / HDL Ratio, Uric Acid, BUN,
                          Creatinine
                        </div>
                        <div>
                          BUN/Creatinine Ratio, Total Protein, Albumin,
                          Globulin, Calcium, Phosphorous, Alkaline Phosphatase,
                          SGOT, SGPT, Bilirubin Total, Sodium, Potassium,
                          Chloride, Vitamin B12, Hormones profile (women), PSA
                          (men)
                        </div>
                      </p>
                    </div>
                  </div>

                  <div class="annexure-4">
                    <div class="heading">
                      <p>
                        <b>
                          <u> ANNEXURE – IV</u>
                        </b>
                      </p>
                      <p>
                        <b>
                          <u>LIST OF SMART HOME CARE PLAN SERVICES</u>
                        </b>
                      </p>
                      <span>
                        *Service eligibility is determined after the completion
                        of an assessment of the member by a qualified nurse
                      </span>
                    </div>
                    <div class="annexure_body">
                      <p>
                        <b>
                          <u>
                            The Services stated herein below are third party
                            services and thus, as mentioned in Clause 8 of this
                            T&C, Emoha shall not be liable in any manner
                            whatsoever, towards the same:
                          </u>
                        </b>
                      </p>
                      <ol>
                        <li>
                          Visiting or At-Home Service by a Care Partner (GDA) to
                          provide assistance with Activities of Daily Living and
                          medication reminders
                        </li>

                        <li>
                          Visiting or At-Home Service by a Care Angel (Nurse) to
                          provide assistance with Activities of Daily Living,
                          and including but not limited to:
                          <div>
                            <p>
                              medication administration, basic Nursing Services
                              such as Ryle’s tube feeding, PEG tube feeding,
                              injections, wound care, pressure sore care,
                              catheter insertion or removal, or advanced Nursing
                              Services such as tracheostomy suctioning,
                              CPAP/BIPAP care, care of an individual on a
                              ventilator etc.
                            </p>
                          </div>
                        </li>
                        <li>Visiting Physical Therapy Services.</li>
                        <li>
                          Assistance with procurement of Durable Medical
                          Equipment (DME). DME is purchased via third party
                          suppliers and will be charged separately. We also
                          shall not be responsible for the quality of the same.
                        </li>
                        <li>
                          Care Management and Supervision by senior nursing
                          staff.
                        </li>
                        <li>
                          All services included in the Empower Plan, with the
                          exception of regular care calls.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row footer">
            <div className="container">
              <form onSubmit={this.handleFormSubmit}>
                <div class="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="invalidCheck2"
                      required
                    />
                    <label class="form-check-label" for="invalidCheck2">
                      Agree to terms and conditions
                    </label>
                  </div>
                  {!isSubmitDisabled ? <button className="button" disabled>Submit</button> :
                  <div className="submit_button .d-flex justify-content-between">
                    <button
                      className="button button-primary"
                      id="submitForm"
                      disabled={!isSubmitDisabled}
                      type="submit"
                    >
                      Submit
                    </button>
                 
                  </div> }
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}
