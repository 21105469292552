import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, Select, Checkbox, message } from "antd";
import { ZoomMtg } from "@zoomus/websdk";
import AOS from "aos";
import Marquee from "react-marquee-slider";
import "antd/dist/antd.css";
import "./App.css";
// import EmohaLogo from "../../assets/images/SignUp.png";
import EmohaLogo from "../../assets/images/Banner_new.png";
import bannerImages1 from "../../../src/assets/images/Strip.jpg";
import "animate.css";
import capitalize from "lodash/capitalize";
import { loadState, saveState, removeState } from "./storage";
import localforage from "localforage";
import OtpInput from "react-otp-input";
import { split, replace } from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const plainOptions = [
  "Inspiring Tuesdays",
  "Learning Wednesdays",
  "Gaming Thursdays",
  "Fun Fridays",
  "Saturday Specials",
  "All the above",
];
const CheckboxGroup = Checkbox.Group;

ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.6/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
  },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12, offset: 12 },
    md: { span: 12, offset: 8 },
    lg: { span: 12, offset: 8 },
  },
};

function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [login, setLogin] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [bannerClicked, setBannerClicked] = useState(false);
  const [bannerSecondText, setBannerSecondText] = useState(false);
  const [isJoined, setJoined] = useState(false);
  const [userUUid, setUserUUid] = useState(null);
  const [phone, setPhone] = useState(null);
  const [phone1, setPhone1] = useState(null);
  const [country, setCountry] = useState(91);
  const [Otp, setOtp] = useState("");

  const [form] = Form.useForm();

  var apiKey = "uq_IB6ChQIqqSeJKrkjIaQ";
  let meetingNumber = null;
  var role = 1;
  var leaveUrl = "/zoom";
  var passWord = "534918";


  
  useEffect(() => {
   
    let url  = window.location.pathname
    meetingNumber = url.split('/')[2]

    ZoomMtg.generateSignature({
      meetingNumber: meetingNumber,
      apiKey: "uq_IB6ChQIqqSeJKrkjIaQ",
      apiSecret: "wTMIcOwrCyAqpwMiIQSps9oJvQ93HFnQW9Sp",
      role: 1,
      success: function (res) {
        getSignature(
          res.result,
          'zoom',
          'zoom',
          "test@test.com",
          'zoom'
        );
        setLogin(false);
        setJoined(true);
      },
    });
  },[]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  const onFinish = (values) => {
    setIsModalVisible(true);
    setFormValues(values);

    const phoneNumber = split(values.phone, " ")[1];
    const phoneCountry = replace(split(values.phone, " ")[0], "+", "");
    setCountry(phoneCountry);
    const formattedPhoneNumber = replace(phoneNumber, "-", "");
    setPhone1(formattedPhoneNumber);
    if (!isModalVisible) {
      fetch("https://api.emoha.com/zoom-webinar-records", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meeting_number: meetingNumber,
          user_name: values.name,
          meeting_password: passWord,
          role: role,
          email: values.email || "test@test.com",
          phone: formattedPhoneNumber,
          country_code: country,
          name: values.name,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserUUid(data.data.uuid);
        });
    }
  };

  const onFinishFailed = () => {
    // do nothing
  };

  function getSignature(signature, name, phone, email, days) {
    console.log('signaturefunc')
    startMeeting(signature, name, phone, email, days);
  }

  function startMeeting(signature, name, phone, email, days) {
    console.log('signaturefunc')
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      disablePreview: true,
      success: () => {
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: name,
          apiKey: apiKey,
          userEmail: email,
          passWord: passWord,
          success: (response) => {
            console.log(response)
            // do nothing
            setJoined(true);
          },
          error: (err) => {
            if (err.errorMessage) {
              // do nothing
              removeState();
              setJoined(false);
            }
          },
        });
      },
      error: () => {
        removeState();
        setJoined(false);
      },
    });
  }

  function handleOk() {
    setBannerClicked(false);
    setBannerSecondText(true);
  }

  function handleCancel() {
    setBannerClicked(false);
    setBannerSecondText(true);
  }

  function handleOnFinishEventForm(value) {
    fetch(`https://api.emoha.com/zoom-webinar-records/`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        is_banner_clicked: true,
        uuid: userUUid,
        selected_days: formValues.selected_days,
      }),
    });
    setBannerClicked(false);
    setBannerSecondText(true);
  }

  const handleOtpVerify = () => {
    fetch(`https://api.emoha.com/api/v1/zoom/verify-otp`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        country_code: country,
        mobile_number: phone1,
        otp: Otp,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.is_zoom_login_otp_verified) {
          ZoomMtg.generateSignature({
            meetingNumber: meetingNumber,
            apiKey: "uq_IB6ChQIqqSeJKrkjIaQ",
            apiSecret: "wTMIcOwrCyAqpwMiIQSps9oJvQ93HFnQW9Sp",
            role: 0,
            success: function (res) {
              const myLocalState = {
                res: res.result,
                name: formValues.name,
                phone: formValues.phone,
                email: formValues.email || "test@test.com",
                days: formValues.selected_days,
              };
              setFormValues(myLocalState);
              saveState(myLocalState);
              getSignature(
                res.result,
                formValues.name,
                formValues.phone,
                formValues.email || "test@test.com",
                formValues.selected_days
              );
              setLogin(false);
              setJoined(true);
            },
          });
        }
        if (!data.is_zoom_login_otp_verified) {
          message.error("Wrong OTP");
          setLogin(true);
          setIsModalVisible(false);
          setOtp(null);
        }
      });
  };

  return (
    <div style={{ width: "100%", zIndex: 1 }}>
      {login ? (
        <div style={{ width: "100%" }}>
          <Form
            className="form-inline-1"
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="container-1">
              <React.Fragment>
                <div className="header-section" style={{ padding: 0 }}>
                  <img
                    src={EmohaLogo}
                    alt="Emoha"
                    style={{ height: "162px" }}
                  />
                </div>
              </React.Fragment>

              <div style={{ backgroundColor: "#ffcc2e" }}>
                <div className="header-subsection">
                  <h3
                    className="header-subsection-tagline"
                    style={{ color: "rgb(145, 18, 35)" }}
                  >
                    #EldersFirstFestival
                  </h3>
                </div>
                {isModalVisible ? (
                  <div className="verifyOtpContainer">
                    <h3 id="verifyText">Verify Otp</h3>
                    <OtpInput
                      /*   containerStyle="verifyOtpContainer" */
                      inputStyle="verifyOtpInput"
                      isInputNum={true}
                      value={Otp}
                      onChange={(e) => setOtp(e)}
                      numInputs={4}
                    />
                    <Button
                      className="button button-primary"
                      htmlType="submit"
                      onClick={handleOtpVerify}
                      style={{
                        textAlign: "center",
                        padding: "5px",
                        fontSize: "19px",
                        width: "50%",
                        height: 60,
                      }}
                    >
                      Go Live
                    </Button>

                    <div
                      style={{
                        textAlign: "center",
                        padding: "5px",
                        fontSize: "16px",
                      }}
                    >
                      To attend the live streaming session <br />
                      click on the <b>GO Live </b> button
                    </div>
                  </div>
                ) : (
                  <div>
                    <Form.Item
                      label="Username"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Invalid User Name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Username"
                        className="inputStyle"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Phone Number"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Invalid Phone number!",
                        },
                      ]}
                    >
                      <div className="input-inliner d-flex align-items-center justify-content-between">
                        <PhoneInput
                          country={"in"}
                          value={phone}
                          className="inputStyle"
                          onChange={(phoneData) => setPhone(phoneData)}
                        />
                      </div>
                    </Form.Item>
                    {/*  <Form.Item
                      name="selected_days"
                      label="Please select Days"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <CheckboxGroup
                        options={plainOptions}
                        style={{ width: 300, marginLeft: "10px" }}
                      />
                    </Form.Item> */}

                    <Button
                      className="button button-primary"
                      htmlType="submit"
                      style={{
                        width: "80%",
                        height: 60,
                        marginLeft: "10%",
                        fontSize: "20px",
                        color: "#fff",
                      }}
                    >
                      Send OTP
                    </Button>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "5px",
                        fontSize: "16px",
                      }}
                    >
                      To attend the live streaming session <br />
                      click on the <b>GO Live </b> button
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      ) : (
        false
      )}
     
      <Modal
        visible={bannerClicked}
        footer={null}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="banner-text">
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={handleOnFinishEventForm}
          >
            {/*   <Form.Item
              name="selected_days"
              label="Please select Days"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <CheckboxGroup options={plainOptions} />
            </Form.Item> */}
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default App;
