import React from 'react';

import get from 'lodash/get';
import ResponderService from '../../services/ResponderService';
import PageLoader from '../../components/PageLoader';
export default class ResponderKYC extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responder: null,
      isSubmitDisabled: false,
      pageLoader: true,
      completionText: null,
    };

    this.responderService = new ResponderService();
  }

  componentDidMount() {
    this.getResponderDetails();
  }

  getResponderDetails() {
    this.responderService
      .getResponderDetails(this.props.match.params.id)
      .then((response) => {
        if (response && response.data) {
          this.setState({ responder: response.data, pageLoader: false });
        } else {
          this.setState({ pageLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ pageLoader: false }, () => {
          alert('Could not fetch volunteer details. Please try again later.');
        });
      });
  }

  updateResponderStatus = (verificationStatus) => {
    this.setState({
      isSubmitDisabled: true,
      pageLoader: true,
      completionText: null,
    });
    this.responderService
      .updateResponderStatus(this.props.match.params.id, {
        verificationStatus,
      })
      .then((response) => {
        this.setState({ isSubmitDisabled: false, pageLoader: false }, () => {
          if (verificationStatus === '1') {
            this.setState({ completionText: 'Volunteer profile verified.' });
          } else {
            this.setState({ completionText: 'Volunteer profile rejected.' });
          }
        });

        this.getResponderDetails();
      })
      .catch((error) => {
        this.setState({ isSubmitDisabled: false, pageLoader: false }, () => {
          alert(
            'Unable to update volunteer status right now. Please try again later.'
          );
        });
      });
  };

  getAnotherResponder = (pos) => {
    this.setState({ pageLoader: true });

    this.responderService
      .getAnotherResponder({
        responder_id: this.props.match.params.id,
        pos,
      })
      .then((res) => {
        this.setState({ pageLoader: false }, () => {
          if (res.data) {
            window.location.href = `/kyc/${res.data}`;
          } else {
            alert('Could not fetch volunteer details. Please try again later.');
          }
        });
      })
      .catch((err) => {
        this.setState({ pageLoader: false }, () => {
          alert('Could not fetch volunteer details. Please try again later.');
        });
      });
  };

  render() {
    const {
      responder,
      isSubmitDisabled,
      pageLoader,
      completionText,
    } = this.state;
    const identityProofs = get(responder, 'identityProofs', []);
    const verificationStatus = get(
      responder,
      'userableResponder.veri_status',
      null
    );
    return (
      <>
        <div className='container-wrapper'>
          <div className='container-wrapper-form' style={{ maxWidth: '992px' }}>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 '>
                <div className='form-section responder-details'>
                  <h4 className='form-section-heading'>Volunteer Details</h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 '>
                <div
                  className='form-section'
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                >
                  <div className='input-wrapper'>
                    <label htmlFor='firstName' className='input-label'>
                      First Name
                    </label>
                    <input
                      type='text'
                      id='firstName'
                      className='input-field'
                      placeholder='First Name'
                      value={get(responder, 'first_name')}
                      disabled
                    />
                  </div>
                  <div className='input-wrapper'>
                    <label htmlFor='lastName' className='input-label'>
                      Last Name
                    </label>
                    <input
                      type='text'
                      id='lastName'
                      placeholder='Last Name'
                      value={get(responder, 'last_name')}
                      className='input-field'
                      disabled
                    />
                  </div>
                  {/* <div className='input-wrapper'>
                    <label htmlFor='mobile' className='input-label'>
                      Mobile Number
                    </label>
                    <div className='input-inliner d-flex align-items-center justify-content-between'>
                      <input
                        type='text'
                        id='countryCode'
                        placeholder='91'
                        value={get(responder, 'country_code')}
                        className='input-field input-select'
                        disabled
                      />
                      <input
                        type='text'
                        id='mobile'
                        placeholder='Mobile Number'
                        value={get(responder, 'mobile_number')}
                        className='input-field'
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className='input-wrapper'>
                    <label htmlFor='country' className='input-label'>
                      Country
                    </label>
                    <input
                      type='text'
                      id='country'
                      placeholder='Country'
                      value={get(responder, 'userableResponder.country')}
                      className='input-field'
                      disabled
                    />
                  </div>
                  <div className='input-wrapper'>
                    <label htmlFor='state' className='input-label'>
                      State
                    </label>
                    <input
                      type='text'
                      id='state'
                      placeholder='State'
                      value={get(responder, 'userableResponder.state')}
                      className='input-field'
                      disabled
                    />
                  </div>
                  <div className='input-wrapper'>
                    <label htmlFor='city' className='input-label'>
                      City
                    </label>
                    <input
                      type='text'
                      id='city'
                      placeholder='City'
                      value={get(responder, 'userableResponder.city')}
                      className='input-field'
                      disabled
                    />
                  </div>
                  {/* <div className='input-wrapper'>
                    <label htmlFor='locality' className='input-label'>
                      Locality
                    </label>
                    <input
                      type='text'
                      id='locality'
                      placeholder='Locality'
                      value={get(responder, 'userableResponder.locality')}
                      className='input-field'
                      disabled
                    />
                  </div> */}
                  {/* <div className='input-wrapper'>
                    <label htmlFor='address' className='input-label'>
                      Address
                    </label>
                    <textarea
                      rows={4}
                      type='text'
                      id='address'
                      placeholder='Address'
                      value={get(responder, 'userableResponder.location')}
                      className='input-field'
                      disabled
                    ></textarea>
                  </div> */}
                  {/* <div className='input-wrapper'>
                    <label htmlFor='pincode' className='input-label'>
                      Pincode
                    </label>
                    <input
                      type='text'
                      id='pincode'
                      placeholder='Pincode'
                      value={get(responder, 'userableResponder.pincode')}
                      className='input-field'
                      disabled
                    />
                  </div> */}
                  <div className='input-wrapper'>
                    <label htmlFor='verification' className='input-label'>
                      Verification Status
                    </label>
                    <input
                      type='text'
                      id='verification'
                      placeholder='Verification Status'
                      value={
                        verificationStatus
                          ? verificationStatus === '1'
                            ? 'Verified'
                            : verificationStatus === '2'
                            ? 'Rejected'
                            : 'Pending'
                          : ''
                      }
                      className='input-field'
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='form-section responder-details'>
                  <div className='input-wrapper'>
                    <label htmlFor='pincode' className='input-label'>
                      Identity Proof
                    </label>
                    {identityProofs.length ? (
                      identityProofs.map((proof, index) => {
                        return (
                          <img
                            key={index}
                            className='responder-image'
                            src={proof.url}
                            alt='Responder Identity Proof'
                          ></img>
                        );
                      })
                    ) : (
                      <p>No proofs uploaded</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {completionText ? (
              <div className='row'>
                <div className='col-12'>
                  <div className='status-text'>
                    <p>{completionText}</p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className='row'>
              <div className='col-12'>
                {verificationStatus !== '1' ? (
                  <div className='status-wrapper'>
                    <button
                      className='btn btn-success status-wrapper-button'
                      type='button'
                      disabled={isSubmitDisabled}
                      onClick={() => this.updateResponderStatus('1')}
                    >
                      Verify
                    </button>
                    <button
                      className='btn btn-danger status-wrapper-button'
                      type='button'
                      disabled={isSubmitDisabled}
                      onClick={() => this.updateResponderStatus('2')}
                    >
                      Reject
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <hr />

            <div className='row'>
              <div className='col-6'>
                <div className='status-wrapper'>
                  <button
                    className='button button-secondary status-wrapper-button'
                    type='button'
                    onClick={() => this.getAnotherResponder('prev')}
                  >
                    {'< Previous'}
                  </button>
                </div>
              </div>
              <div className='col-6'>
                <div className='status-wrapper d-flex justify-content-end'>
                  <button
                    className='button button-secondary status-wrapper-button'
                    type='button'
                    onClick={() => this.getAnotherResponder('next')}
                  >
                    {'Next >'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {pageLoader && <PageLoader />}
      </>
    );
  }
}
