import axiosInstance from './index';
import { RESPONDER_DETAILS, RESPONDER_QUEUE,GET_NAME,TNC_SUBMIT } from '../constants/constants';

export default class ResponderService {
  getResponderDetails(responder_id) {
    return axiosInstance.get(RESPONDER_DETAILS.replace(':id', responder_id));
  }

  updateResponderStatus(responder_id, payload) {
    return axiosInstance.put(
      RESPONDER_DETAILS.replace(':id', responder_id),
      payload
    );
  }

  getAnotherResponder(payload) {
    return axiosInstance.post(RESPONDER_QUEUE, payload);
  }

  getName(responder_id){
    return axiosInstance.get(
      GET_NAME.replace(':id', responder_id)
      );
  }

  acceptTnc(responder_id,payload){
    return axiosInstance.put(
      TNC_SUBMIT.replace(':id', responder_id),
      payload
      );
  }
}
