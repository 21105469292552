import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import VolunteerForm from './containers/VolunteerForm';
import ElderForm from './containers/ElderForm';
import ResponderKYC from './containers/ResponderKYC';
import TermsAndCondition from './containers/TermsAndCondition';
import Zoom from './containers/zoom';
import ZoomStartMeeting from './containers/ZoomStartMeeting';

import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
//app class
export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={VolunteerForm} />
          <Route exact path='/elder' component={ElderForm} />
          <Route exact path='/kyc/:id' component={ResponderKYC} />
          <Route exact path='/terms' component={TermsAndCondition} />
          <Route exact path='/terms/:id' component={TermsAndCondition} />
          <Route exact path='/zoom' component={Zoom} />
          <Route exact path='/zoom-start-meeting/:id' component={ZoomStartMeeting} />
        </Switch>
      </Router>
    );
  }
}
