/*global google*/
import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';

import PageLoader from '../../components/PageLoader';

import { BASE_API_URL } from '../../constants/constants';

import EmohaLogo from '../../assets/images/emoha-logo.png';

export default class ElderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registrationApiUrl: `elder/registration`,
      countriesList: [],
      statesList: [],
      citiesList: [],
      formData: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        countryCode: '91',
        emailAddress: '',
        genderName: '',
        countryName: '',
        stateName: '',
        cityName: '',
        localityName: '',
        addressLine1: '',
        addressLine2: '',
        dateOfBirth: new Date(),
        geoLatitude: null,
        geoLongitude: null,
        agreeTerms: false,
      },
      standalone: true,
      pageLoader: false,
      validationErrors: [],
      isSubmitEnabled: true,
      isFormSubmitted: false,
    };

    this.axiosInstance = null;
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    if (params && params.standalone === 'false') {
      this.setState({ standalone: false });
    }

    this.renderGoogleMaps();
    this.initializeAxiosInstance();
    this.getCountriesList();
  }

  initializeAxiosInstance = () => {
    this.axiosInstance = axios.create({
      baseURL: BASE_API_URL,
      timeout: 60000,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  renderGoogleMaps = () => {
    var map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 28.6109872, lng: 77.1628123 },
      zoom: 11,
      mapTypeId: 'roadmap',
    });

    // Create the search box and link it to the UI element.
    var input = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', function () {
      searchBox.setBounds(map.getBounds());
    });
    var markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      var places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }
      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];
      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };
        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
            draggable: true,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        google.maps.event.addListener(markers[0], 'dragend', (marker) => {
          this.setState((state) => ({
            ...state,
            formData: {
              ...state.formData,
              geoLatitude: marker.latLng.lat(),
              geoLongitude: marker.latLng.lng(),
            },
          }));
        });

        this.setState((state) => ({
          ...state,
          formData: {
            ...state.formData,
            geoLatitude: place.geometry.location.lat(),
            geoLongitude: place.geometry.location.lng(),
          },
        }));
      });

      map.fitBounds(bounds);
    });
  };

  getCountriesList() {
    this.setState({ pageLoader: true });

    this.axiosInstance
      .get(`countries`)
      .then((responseData) => {
        if (responseData) {
          if (responseData.data.length !== 0) {
            this.setState({
              pageLoader: false,
              countriesList: responseData.data,
            });
          }
        }
      })
      .catch((errorData) => {
        console.log('ERROR DURING FETCHING OF COUNTRIES', errorData);
      });
  }

  getStatesList() {
    const { countryName } = this.state.formData;

    if (countryName !== '') {
      this.setState({ pageLoader: true });

      this.axiosInstance
        .get(`states?country=${countryName}`)
        .then((responseData) => {
          if (responseData) {
            if (responseData.data.length !== 0) {
              this.setState(
                { pageLoader: false, statesList: responseData.data },
                () => {
                  this.getCitiesList();
                }
              );
            }
          }
        })
        .catch((errorData) => {
          console.log(
            'ERROR DURING FETCHING OF STATES',
            errorData.response.data
          );
        });
    }
  }

  getCitiesList() {
    const { stateName } = this.state.formData;

    if (stateName !== '') {
      this.setState({ pageLoader: true });

      this.axiosInstance
        .get(`cities?state=${stateName}`)
        .then((responseData) => {
          if (responseData) {
            this.setState({ pageLoader: false });

            if (responseData.data.length !== 0) {
              this.setState({
                citiesList: responseData.data,
              });
            }
          }
        })
        .catch((errorData) => {
          console.log(
            'ERROR DURING FETCHING OF CITIES',
            errorData.response.data
          );
        });
    }
  }

  handleCheckboxUpdate = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        agreeTerms: !state.formData.agreeTerms,
      },
    }));
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState(
      (state) => ({
        ...state,
        formData: {
          ...state.formData,
          [`${fieldName}`]: fieldValue,
        },
      }),
      () => {
        if (fieldName === 'countryName') {
          this.getStatesList();
        } else if (fieldName === 'stateName') {
          this.getCitiesList();
        }
      }
    );
  };

  validateFormData = () => {
    let validationErrors = [];
    const { formData } = this.state;

    this.setState({ validationErrors: [] });

    const keysArr = Object.keys(formData);

    for (let index = 0; index < keysArr.length; index++) {
      if (!formData[keysArr[index]] && keysArr[index] !== 'cityName') {
        validationErrors.push(
          <div className='validation-errors-item'>
            Error: All fields in the form except the City are required.
          </div>
        );

        this.setState((state) => ({
          ...state,
          validationErrors: validationErrors,
        }));

        return false;
      }

      if (keysArr[index] === 'agreeTerms') {
        if (!formData[keysArr[index]]) {
          validationErrors.push(
            <div className='validation-errors-item'>
              Error: Please accept the Emoha Terms & Conditions.
            </div>
          );
        }
      }
    }

    this.setState((state) => ({
      ...state,
      validationErrors: validationErrors,
    }));

    if (validationErrors.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  clearFormData = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        countryCode: '91',
        emailAddress: '',
        genderName: '',
        countryName: '',
        stateName: '',
        cityName: '',
        localityName: '',
        addressLine1: '',
        addressLine2: '',
        dateOfBirth: new Date(),
        geoLatitude: null,
        geoLongitude: null,
        agreeTerms: false,
      },
      validationErrors: [],
      isSubmitEnabled: true,
      isFormSubmitted: false,
    }));
  };

  handleFormSubmit = () => {
    const { formData, registrationApiUrl } = this.state;

    let isFormValid = this.validateFormData();

    if (isFormValid) {
      this.setState({ isSubmitEnabled: false, pageLoader: true });

      const dataPayload = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.emailAddress,
        country_code: formData.countryCode,
        mobile_number: formData.mobileNumber,
        address_line_1: formData.addressLine1,
        address_line_2: formData.addressLine2,
        gender: formData.genderName,
        city: formData.cityName,
        state: formData.stateName,
        country: formData.countryName,
        locality: formData.localityName,
        dob: formData.dateOfBirth,
        geo_latitude: formData.geoLatitude,
        geo_longitude: formData.geoLongitude,
      };

      this.axiosInstance
        .post(`${registrationApiUrl}`, dataPayload)
        .then((responseObject) => {
          this.setState({
            isSubmitEnabled: true,
            isFormSubmitted: false,
            pageLoader: false,
          });

          console.log('RESPONSE RECEIVED', responseObject);

          this.clearFormData();

          if (this.state.standalone) {
            window.location.href = 'https://emoha.com/eldersfirst';
          } else {
            this.setState({ isFormSubmitted: true });
          }
        })
        .catch((errorData) => {
          this.setState({
            isSubmitEnabled: true,
            isFormSubmitted: false,
            pageLoader: false,
          });

          console.log('ERROR DURING REGISTRATION', errorData);

          alert(
            `Error! The request could not be processed due to the following error - ${errorData.response.data.message}`
          );
        });
    }
  };

  handleFormCancel = () => {
    window.location.href = 'https://emoha.com/';
  };

  render() {
    const {
      formData,
      pageLoader,
      standalone,
      isSubmitEnabled,
      validationErrors,
      isFormSubmitted,
    } = this.state;

    return (
      <>
        <div className='container-wrapper'>
          {!isFormSubmitted ? (
            <div className='container-wrapper-form'>
              {standalone && (
                <React.Fragment>
                  <div className='header-section'>
                    <img
                      src={EmohaLogo}
                      className='header-section-logo'
                      alt='Emoha'
                    />
                  </div>

                  <div className='header-subsection'>
                    <h3 className='header-subsection-tagline'>
                      #MissionEldersFirst
                    </h3>
                    <h5 className='header-subsection-title'>
                      Elder Registration Form
                    </h5>
                  </div>
                </React.Fragment>
              )}

              <div className='form-section'>
                <h4 className='form-section-heading'>Personal Details</h4>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='firstName' className='input-label'>
                        First Name
                      </label>
                      <input
                        type='text'
                        id='firstName'
                        className='input-field'
                        required={true}
                        value={formData.firstName}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'firstName',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='lastName' className='input-label'>
                        Last Name
                      </label>
                      <input
                        type='text'
                        id='lastName'
                        className='input-field'
                        required={true}
                        value={formData.lastName}
                        onChange={(event) =>
                          this.handleFieldUpdate('lastName', event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='emailAddress' className='input-label'>
                        Email Address
                      </label>
                      <input
                        type='email'
                        id='emailAddress'
                        className='input-field'
                        required={true}
                        value={formData.emailAddress}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'emailAddress',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='phoneNumber' className='input-label'>
                        Phone Number
                      </label>
                      <div className='input-inliner d-flex align-items-center justify-content-between'>
                        <select
                          id='countryCode'
                          className='input-field input-select'
                          required={true}
                          value={formData.countryCode}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              'countryCode',
                              event.target.value
                            )
                          }
                        >
                          <option value='91'>+91</option>
                          <option value='1'>+1</option>
                          <option value='20'>+20</option>
                          <option value='27'>+27</option>
                          <option value='33'>+33</option>
                          <option value='44'>+44</option>
                          <option value='49'>+49</option>
                          <option value='60'>+60</option>
                          <option value='61'>+61</option>
                          <option value='62'>+62</option>
                          <option value='65'>+65</option>
                          <option value='254'>+254</option>
                          <option value='254'>+254</option>
                          <option value='880'>+880</option>
                          <option value='965'>+965</option>
                          <option value='966'>+966</option>
                          <option value='971'>+971</option>
                          <option value='973'>+973</option>
                          <option value='974'>+974</option>
                        </select>
                        <input
                          type='number'
                          id='phoneNumber'
                          className='input-field input-mobile'
                          minLength='8'
                          maxLength='12'
                          required={true}
                          value={formData.mobileNumber}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              'mobileNumber',
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='dateObBirth' className='input-label'>
                        Date of Birth
                      </label>
                      <DatePicker
                        maxDate={new Date()}
                        showYearDropdown={true}
                        showMonthDropdown={true}
                        dateFormat={'dd/MM/yyyy'}
                        value={formData.dateOfBirth}
                        className='input-field input-field-dp'
                        selected={this.state.formData.dateOfBirth}
                        onChange={(value) =>
                          this.handleFieldUpdate('dateOfBirth', value)
                        }
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='genderName' className='input-label'>
                        Gender
                      </label>
                      <select
                        id='genderName'
                        className='input-field input-select-fw'
                        required={true}
                        value={formData.genderName}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'genderName',
                            event.target.value
                          )
                        }
                      >
                        <option value=''>Select a Gender</option>
                        <option value='1'>Male</option>
                        <option value='2'>Female</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-section'>
                <h4 className='form-section-heading'>Address Details</h4>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='countryName' className='input-label'>
                        Country
                      </label>

                      <select
                        id='countryName'
                        className='input-field input-select-fw'
                        required={true}
                        value={formData.countryName}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'countryName',
                            event.target.value
                          )
                        }
                      >
                        <option value=''>Select a Country</option>

                        {this.state.countriesList &&
                          this.state.countriesList.map((item, index) => {
                            return (
                              <option key={index} value={`${item.country}`}>
                                {item.country}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='stateName' className='input-label'>
                        State
                      </label>

                      <select
                        id='stateName'
                        className='input-field input-select-fw'
                        required={true}
                        value={formData.stateName}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'stateName',
                            event.target.value
                          )
                        }
                      >
                        <option value=''>Select a State</option>

                        {this.state.formData.countryName !== '' &&
                          this.state.statesList &&
                          this.state.statesList.map((item, index) => {
                            return (
                              <option key={index} value={`${item.state}`}>
                                {item.state}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='cityName' className='input-label'>
                        City
                      </label>

                      <select
                        id='cityName'
                        className='input-field input-select-fw'
                        required={true}
                        value={formData.cityName}
                        onChange={(event) =>
                          this.handleFieldUpdate('cityName', event.target.value)
                        }
                      >
                        <option value=''>Select a City</option>

                        {this.state.formData.stateName !== '' &&
                          this.state.citiesList &&
                          this.state.citiesList.map((item, index) => {
                            return (
                              <option key={index} value={`${item.city}`}>
                                {item.city}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='localityName' className='input-label'>
                        Locality
                      </label>
                      <input
                        type='text'
                        required={true}
                        id='localityName'
                        className='input-field'
                        value={formData.localityName}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'localityName',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className='input-wrapper'>
                      <label htmlFor='pac-input' className='input-label'>
                        Location
                      </label>

                      <input
                        type='text'
                        id='pac-input'
                        className='input-field input-map'
                        placeholder=''
                      />

                      <div
                        id='map'
                        style={{
                          width: '100%',
                          height: '300px',
                          position: 'relative',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='addressLine1' className='input-label'>
                        Address Line 1
                      </label>
                      <input
                        type='text'
                        required={true}
                        id='addressLine1'
                        className='input-field'
                        value={formData.addressLine1}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'addressLine1',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='addressLine2' className='input-label'>
                        Address Line 2
                      </label>
                      <input
                        type='text'
                        required={true}
                        id='addressLine2'
                        className='input-field'
                        value={formData.addressLine2}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'addressLine2',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-section'>
                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label
                        htmlFor='agreeTerms'
                        className='input-label d-flex align-items-center justify-content-start'
                      >
                        <input
                          type='checkbox'
                          className='input-checkbox'
                          id='agreeTerms'
                          required={true}
                          onChange={() => this.handleCheckboxUpdate()}
                        />
                        I agree to the
                        <a
                          href='https://drive.google.com/file/d/1JvCIe-LIGGcz6jHq6pgET0yMG_iozqAM/view'
                          target='_new'
                        >
                          Terms & Conditions
                        </a>
                      </label>
                    </div>
                  </div>
                </div>

                {validationErrors.length !== 0 && (
                  <div className='row'>
                    <div className='col-12 col-sm-6'>
                      <div className='validation-errors'>
                        {validationErrors.map((item, index) => {
                          return (
                            <React.Fragment key={index}>{item}</React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                <div className='row align-items-center'>
                  <div className='col-12 col-sm-6'>
                    <button
                      type='button'
                      className='button button-primary'
                      id='submitForm'
                      disabled={!isSubmitEnabled}
                      onClick={() => this.handleFormSubmit()}
                    >
                      Submit
                    </button>

                    <button
                      type='button'
                      className='button button-secondary'
                      id='cancelForm'
                      disabled={!isSubmitEnabled}
                      onClick={() => this.handleFormCancel()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='container-wrapper-form'>
              <div className='form-section'>
                <h4 className='form-section-heading'>Success!</h4>

                <p className='form-section-description'>
                  Thank you for your interest in becoming a part of the Emoha
                  family. Someone from our team will reach out to you soon.
                </p>

                <div id='map' style={{ display: 'none' }} />
              </div>
            </div>
          )}
        </div>

        {pageLoader && <PageLoader />}
      </>
    );
  }
}
