/*global google*/
import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
// import DatePicker from 'react-datepicker';
import PageLoader from '../../components/PageLoader';

import { BASE_API_URL } from '../../constants/constants';

import EmohaLogo from '../../assets/images/emoha-logo.png';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registrationApiUrl: `responder/registration`,
      formData: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        countryCode: '91',
        emailAddress: '',
        genderName: '',
        // countryName: 'India',
        // stateName: '',
        cityName: '',
        // localityName: '',
        streetAddress: '',
        // pinCode: '',
        // dateOfBirth: new Date(),
        // anniversaryDate: new Date(),
        geoLatitude: null,
        geoLongitude: null,
        identityProof: null,
        agreeTerms: false,
        aadhaar: '',
      },
      standalone: true,
      pageLoader: false,
      validationErrors: [],
      isSubmitEnabled: true,
      isFormSubmitted: false,
    };

    this.axiosInstance = null;
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    if (params && params.standalone === 'false') {
      this.setState({ standalone: false });
    }

    this.renderGoogleMaps();
    this.initializeAxiosInstance();
  }

  initializeAxiosInstance = () => {
    this.axiosInstance = axios.create({
      baseURL: BASE_API_URL,
      timeout: 60000,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  renderGoogleMaps = () => {
    var map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 28.6109872, lng: 77.1628123 },
      zoom: 11,
      mapTypeId: 'roadmap',
    });

    // Create the search box and link it to the UI element.
    var input = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', function () {
      searchBox.setBounds(map.getBounds());
    });
    var markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      var places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }
      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];
      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };
        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
            draggable: true,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        google.maps.event.addListener(markers[0], 'dragend', (marker) => {
          this.setState((state) => ({
            ...state,
            formData: {
              ...state.formData,
              geoLatitude: marker.latLng.lat(),
              geoLongitude: marker.latLng.lng(),
            },
          }));
        });

        this.setState((state) => ({
          ...state,
          formData: {
            ...state.formData,
            geoLatitude: place.geometry.location.lat(),
            geoLongitude: place.geometry.location.lng(),
          },
        }));
      });

      map.fitBounds(bounds);
    });
  };

  handleCheckboxUpdate = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        agreeTerms: !state.formData.agreeTerms,
      },
    }));
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  validateFormData = () => {
    let validationErrors = [];
    const { formData } = this.state;

    this.setState({ validationErrors: [] });

    const keysArr = Object.keys(formData);

    for (let index = 0; index < keysArr.length; index++) {
      if (!formData[keysArr[index]]) {
        validationErrors.push(
          <div className='validation-errors-item'>
            Error: All fields in the form are required.
          </div>
        );

        this.setState((state) => ({
          ...state,
          validationErrors: validationErrors,
        }));

        return false;
      }

      if (keysArr[index] === 'identityProof' && formData[keysArr[index]]) {
        if (
          typeof formData[keysArr[index]] !== 'object' ||
          formData[keysArr[index]].files.length === 0
        ) {
          validationErrors.push(
            <div className='validation-errors-item'>
              Error: Uploading an Identity proof is mandatory.
            </div>
          );
        } else if (formData[keysArr[index]].files[0].size / 1024 / 1024 > 20) {
          validationErrors.push(
            <div className='validation-errors-item'>
              Error: Uploaded Identity proof is too large. Maximum file size
              permitted is 20 MB.
            </div>
          );
        } else if (
          formData[keysArr[index]].files[0].type !== 'image/jpeg' &&
          formData[keysArr[index]].files[0].type !== 'image/png'
        ) {
          validationErrors.push(
            <div className='validation-errors-item'>
              Error: Uploaded Identity proof should be either a JPG or PNG file.
            </div>
          );
        }
      } else if (keysArr[index] === 'agreeTerms') {
        if (!formData[keysArr[index]]) {
          validationErrors.push(
            <div className='validation-errors-item'>
              Error: Please accept the Emoha Volunteer Terms & Conditions.
            </div>
          );
        }
      } else if (
        keysArr[index] === 'aadhaar' &&
        formData[keysArr[index]].length !== 12
      ) {
        validationErrors.push(
          <div className='validation-errors-item'>
            Error: Please enter a valid Aadhaar Number.
          </div>
        );
      }
    }

    this.setState((state) => ({
      ...state,
      validationErrors: validationErrors,
    }));

    if (validationErrors.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  clearFormData = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        countryCode: '91',
        emailAddress: '',
        genderName: '',
        countryName: 'India',
        stateName: '',
        cityName: '',
        localityName: '',
        streetAddress: '',
        pinCode: '',
        dateOfBirth: new Date(),
        anniversaryDate: new Date(),
        geoLatitude: null,
        geoLongitude: null,
        aadhaar: '',
      },
      validationErrors: [],
      isSubmitEnabled: true,
      isFormSubmitted: false,
    }));
  };

  handleFormSubmit = () => {
    const { formData, registrationApiUrl } = this.state;

    let isFormValid = this.validateFormData();

    if (isFormValid) {
      this.setState({ isSubmitEnabled: false, pageLoader: true });

      let dataPayload = new FormData();

      dataPayload.set('first_name', formData.firstName);
      dataPayload.set('last_name', formData.lastName);
      dataPayload.set('email', formData.emailAddress);
      dataPayload.set('country_code', formData.countryCode);
      dataPayload.set('mobile_number', formData.mobileNumber);
      dataPayload.set('address', formData.streetAddress);
      dataPayload.set('gender', formData.genderName);
      dataPayload.set('city', formData.cityName);
      // dataPayload.set('state', formData.stateName);
      // dataPayload.set('country', formData.countryName);
      // dataPayload.set('locality', formData.localityName);
      // dataPayload.set('pincode', formData.pinCode);
      // dataPayload.set('dob', formData.dateOfBirth);
      // dataPayload.set('anniversary', formData.anniversaryDate);
      dataPayload.set('geo_latitude', formData.geoLatitude);
      dataPayload.set('geo_longitude', formData.geoLongitude);
      dataPayload.set('aadhaar', formData.aadhaar);
      dataPayload.append('file', formData.identityProof.files[0]);

      fetch(`${BASE_API_URL}/${registrationApiUrl}`, {
        method: 'POST',
        body: dataPayload,
      })
        .then((responseData) => responseData.json())
        .then((responseObject) => {
          this.setState({
            isSubmitEnabled: true,
            isFormSubmitted: false,
            pageLoader: false,
          });

          console.log('RESPONSE RECEIVED', responseObject);

          if (responseObject.code >= 400) {
            alert(
              `Error! The request could not be processed due to the following error - ${responseObject.message}`
            );
          } else {
            this.clearFormData();

            if (this.state.standalone) {
              window.location.href = 'https://emoha.com/eldersfirst';
            } else {
              this.setState({ isFormSubmitted: true });
            }
          }
        })
        .catch((errorData) => {
          this.setState({
            isSubmitEnabled: true,
            isFormSubmitted: false,
            pageLoader: false,
          });

          console.log('ERROR DURING REGISTRATION', errorData);

          alert(
            `Error! Something went wrong. Please check your internet connection and try again.`
          );
        });
    }
  };

  handleFormCancel = () => {
    window.location.href = 'https://emoha.com/';
  };

  render() {
    const {
      formData,
      pageLoader,
      standalone,
      isSubmitEnabled,
      validationErrors,
      isFormSubmitted,
    } = this.state;

    return (
      <>
        <div className='container-wrapper'>
          {!isFormSubmitted ? (
            <div className='container-wrapper-form'>
              {standalone && (
                <React.Fragment>
                  <div className='header-section'>
                    <img
                      src={EmohaLogo}
                      className='header-section-logo'
                      alt='Emoha'
                    />
                  </div>

                  <div className='header-subsection'>
                    <h3 className='header-subsection-tagline'>
                      #MissionEldersFirst
                    </h3>
                    <h5 className='header-subsection-title'>
                      Volunteer Registration Form
                    </h5>
                  </div>
                </React.Fragment>
              )}

              <div className='form-section'>
                <h4 className='form-section-heading'>Personal Details</h4>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='firstName' className='input-label'>
                        First Name
                      </label>
                      <input
                        type='text'
                        id='firstName'
                        className='input-field'
                        required={true}
                        value={formData.firstName}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'firstName',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='lastName' className='input-label'>
                        Last Name
                      </label>
                      <input
                        type='text'
                        id='lastName'
                        className='input-field'
                        required={true}
                        value={formData.lastName}
                        onChange={(event) =>
                          this.handleFieldUpdate('lastName', event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='emailAddress' className='input-label'>
                        Email Address
                      </label>
                      <input
                        type='email'
                        id='emailAddress'
                        className='input-field'
                        required={true}
                        value={formData.emailAddress}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'emailAddress',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='phoneNumber' className='input-label'>
                        Phone Number
                      </label>
                      <div className='input-inliner d-flex align-items-center justify-content-between'>
                        <select
                          id='countryCode'
                          className='input-field input-select'
                          required={true}
                          value={formData.countryCode}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              'countryCode',
                              event.target.value
                            )
                          }
                        >
                          <option value='91'>+91</option>
                          <option value='1'>+1</option>
                          <option value='20'>+20</option>
                          <option value='27'>+27</option>
                          <option value='33'>+33</option>
                          <option value='44'>+44</option>
                          <option value='49'>+49</option>
                          <option value='60'>+60</option>
                          <option value='61'>+61</option>
                          <option value='62'>+62</option>
                          <option value='65'>+65</option>
                          <option value='254'>+254</option>
                          <option value='254'>+254</option>
                          <option value='880'>+880</option>
                          <option value='965'>+965</option>
                          <option value='966'>+966</option>
                          <option value='971'>+971</option>
                          <option value='973'>+973</option>
                          <option value='974'>+974</option>
                        </select>
                        <input
                          type='number'
                          id='phoneNumber'
                          className='input-field input-mobile'
                          minLength='8'
                          maxLength='12'
                          required={true}
                          value={formData.mobileNumber}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              'mobileNumber',
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='row'>
                <div className='col-12 col-sm-6'>
                  <div className='input-wrapper'>
                    <label htmlFor='dateObBirth' className='input-label'>
                      Date of Birth
                    </label>
                    <DatePicker
                      maxDate={new Date()}
                      showYearDropdown={true}
                      showMonthDropdown={true}
                      dateFormat={'dd/MM/yyyy'}
                      value={formData.dateOfBirth}
                      className='input-field input-field-dp'
                      selected={this.state.formData.dateOfBirth}
                      onChange={(value) =>
                        this.handleFieldUpdate('dateOfBirth', value)
                      }
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-6'>
                  <div className='input-wrapper'>
                    <label htmlFor='anniversaryDate' className='input-label'>
                      Anniversary Date
                    </label>
                    <DatePicker
                      maxDate={new Date()}
                      showYearDropdown={true}
                      showMonthDropdown={true}
                      dateFormat={'dd/MM/yyyy'}
                      value={formData.anniversaryDate}
                      className='input-field input-field-dp'
                      selected={this.state.formData.anniversaryDate}
                      onChange={(value) =>
                        this.handleFieldUpdate('anniversaryDate', value)
                      }
                    />
                  </div>
                </div>
              </div> */}
                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='genderName' className='input-label'>
                        Gender
                      </label>
                      <select
                        id='genderName'
                        className='input-field input-select-fw'
                        required={true}
                        value={formData.genderName}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'genderName',
                            event.target.value
                          )
                        }
                      >
                        <option value=''>Select a Gender</option>
                        <option value='1'>Male</option>
                        <option value='2'>Female</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-section'>
                <h4 className='form-section-heading'>Address Details</h4>

                {/* <div className='row'>
                <div className='col-12 col-sm-6'>
                  <div className='input-wrapper'>
                    <label htmlFor='countryName' className='input-label'>
                      Country
                    </label>
                    <input
                      type='text'
                      required={true}
                      id='countryName'
                      placeholder='India'
                      className='input-field'
                      value={formData.countryName}
                      onChange={(event) =>
                        this.handleFieldUpdate(
                          'countryName',
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-6'>
                  <div className='input-wrapper'>
                    <label htmlFor='stateName' className='input-label'>
                      State
                    </label>
                    <input
                      type='text'
                      required={true}
                      id='stateName'
                      placeholder='Delhi'
                      className='input-field'
                      value={formData.stateName}
                      onChange={(event) =>
                        this.handleFieldUpdate('stateName', event.target.value)
                      }
                    />
                  </div>
                </div>
              </div> */}

                <div className='row'>
                  <div className='col-12'>
                    <div className='input-wrapper'>
                      <label htmlFor='pac-input' className='input-label'>
                        Location
                      </label>

                      <input
                        type='text'
                        id='pac-input'
                        className='input-field input-map'
                        placeholder=''
                      />

                      <div
                        id='map'
                        style={{
                          width: '100%',
                          height: '300px',
                          position: 'relative',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='streetAddress' className='input-label'>
                        Street Address
                      </label>
                      <textarea
                        rows='4'
                        id='streetAddress'
                        className='input-field input-textarea'
                        required={true}
                        value={formData.streetAddress}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'streetAddress',
                            event.target.value
                          )
                        }
                      ></textarea>
                    </div>
                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='cityName' className='input-label'>
                        City
                      </label>
                      <input
                        type='text'
                        required={true}
                        id='cityName'
                        className='input-field'
                        value={formData.cityName}
                        onChange={(event) =>
                          this.handleFieldUpdate('cityName', event.target.value)
                        }
                      />
                    </div>
                  </div>

                  {/* <div className='col-12 col-sm-6'>
                  <div className='input-wrapper'>
                    <label htmlFor='localityName' className='input-label'>
                      Locality
                    </label>
                    <input
                      type='text'
                      required={true}
                      id='localityName'
                      placeholder='R.K. Puram'
                      className='input-field'
                      value={formData.localityName}
                      onChange={(event) =>
                        this.handleFieldUpdate(
                          'localityName',
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div> */}
                </div>

                {/* <div className='row'>
                <div className='col-12 col-sm-6'>
                  <div className='input-wrapper'>
                    <label htmlFor='pinCode' className='input-label'>
                      PIN Code
                    </label>
                    <input
                      type='text'
                      placeholder='6 Digit Pin Code'
                      id='pinCode'
                      maxLength={6}
                      value={formData.pinCode}
                      className='input-field'
                      required={true}
                      onChange={(event) =>
                        this.handleFieldUpdate('pinCode', event.target.value)
                      }
                    />
                  </div>
                </div>
              </div> */}
              </div>
              <div className='form-section'>
                <h4 className='form-section-heading'>Identity Details</h4>

                <div className='row align-items-center'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='identityProof' className='input-label'>
                        Identity Proof
                      </label>
                      <input
                        type='file'
                        accept='jpg,jpeg,png'
                        id='identityProof'
                        className='input-field'
                        required={true}
                        onChange={(event) =>
                          this.handleFieldUpdate('identityProof', event.target)
                        }
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='aadhaar' className='input-label'>
                        Aadhaar Number
                      </label>
                      <input
                        type='number'
                        required={true}
                        id='aadhaar'
                        className='input-field'
                        value={formData.aadhaar}
                        onChange={(event) =>
                          this.handleFieldUpdate('aadhaar', event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-12'>
                    <p className='input-assist'>
                      We accept <b>Aadhar Card</b> with clear photograph as a
                      proof of identity. Please click a picture of your Aadhar
                      Card in
                      <b> JPG</b> or <b>PNG</b> format and upload it in the
                      space provided. Maximum file size is <b>20 MB</b>.
                    </p>
                  </div>
                </div>
              </div>

              <div className='form-section'>
                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label
                        htmlFor='agreeTerms'
                        className='input-label d-flex align-items-center justify-content-start'
                      >
                        <input
                          type='checkbox'
                          className='input-checkbox'
                          id='agreeTerms'
                          required={true}
                          onChange={() => this.handleCheckboxUpdate()}
                        />
                        I agree to the
                        <a
                          href='https://drive.google.com/file/d/1uBPkWh9oK0VoCL672w-wENF1tRY-1yUx/view'
                          target='_new'
                        >
                          Terms & Conditions
                        </a>
                      </label>
                    </div>
                  </div>
                </div>

                {validationErrors.length !== 0 && (
                  <div className='row'>
                    <div className='col-12 col-sm-6'>
                      <div className='validation-errors'>
                        {validationErrors.map((item, index) => {
                          return (
                            <React.Fragment key={index}>{item}</React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                <div className='row align-items-center'>
                  <div className='col-12 col-sm-6'>
                    <button
                      type='button'
                      className='button button-primary'
                      id='submitForm'
                      disabled={!isSubmitEnabled}
                      onClick={() => this.handleFormSubmit()}
                    >
                      Submit
                    </button>

                    <button
                      type='button'
                      className='button button-secondary'
                      id='cancelForm'
                      disabled={!isSubmitEnabled}
                      onClick={() => this.handleFormCancel()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

              <div className='form-section'>
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='form-section-heading'>
                      Your responsibilities as an Emoha Volunteer
                    </h4>

                    <ul className='form-section-list'>
                      <li>
                        Volunteers need to be aware that they are going to be
                        taking care of elders and therefore need to take extra
                        care of their own health and hygiene.
                      </li>
                      <li>
                        In case of any symptoms whatsoever, the volunteer is to
                        report back to the Coordinator’s office and only after
                        approval by the clinical director will he/she be allowed
                        to take on any volunteering responsibility.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='form-section'>
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='form-section-heading'>
                      What will you do as an Emoha Volunteer?
                    </h4>

                    <ul className='form-section-list'>
                      <li>
                        Verified Volunteer will be assigned requests by elders
                        within their neighbourhood by the Emoha Call Center.
                      </li>
                      <li>
                        Each Volunteer will be allocated a “Moment of Kind
                        Action” (MOKA) moment - an opportunity to help complete
                        an errand for an elder
                      </li>
                      <li>
                        Each MOKA will have details of the elder’s name,
                        address, Google Map URL, phone number and details of the
                        specific errand / support need. Volunteer will have the
                        discretion to accept / deny a MOKA request after
                        evaluating the same.
                      </li>
                      <li>
                        On completion of an errand, in case it is a delivery of
                        a good, volunteer will drop off the same at the doorstep
                        of elder’s home and adhere to the hygiene and sanitation
                        precautions as recommended by Emoha. Payment for the
                        supplies will be made by the elder to the volunteer
                        directly.
                      </li>
                      <li>
                        Volunteer will notify the Emoha Call Center that the job
                        has been completed.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='form-section'>
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='form-section-heading'>DO’s and Don’t’s</h4>

                    <ul className='form-section-list'>
                      <li>
                        All volunteers must wear a mask and have hand sanitizers
                        with them
                      </li>
                      <li>Volunteers must stand 6 feet away from elder</li>
                      <li>
                        Please ensure your own safety as well as elders’ safety
                      </li>
                      <li>
                        If you purchase any goods for an elder, always ask the
                        shop for a bill
                      </li>
                      <li>Try to use online modes to accept payments.</li>
                      <li>
                        Volunteers are not allowed to enter the elder’s home
                      </li>
                      <li>
                        Volunteers should inform the Coordinator’s office in
                        case Volunteer is unable to full fill the MOKA work
                        assigned by the elder.
                      </li>
                      <li>You do not need to own a vehicle to volunteer</li>
                      <li>Politely refuse to buy things for free.</li>
                      <li>
                        Be responsive to elders needs and try to help as fast as
                        possible.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='container-wrapper-form'>
              <div className='form-section'>
                <h4 className='form-section-heading'>Success!</h4>

                <p className='form-section-description'>
                  Thank you for your interest in becoming a volunteer. Someone
                  from our team will reach out to you soon.
                </p>

                <div id='map' style={{ display: 'none' }} />
              </div>
            </div>
          )}
        </div>

        {pageLoader && <PageLoader />}
      </>
    );
  }
}
